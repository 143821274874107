import { alpha, Card, styled } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { LoaderIcon } from 'components/atoms/LoaderIcon';
import { ModalTitleWithDetails } from 'components/atoms/ModalTitleWithDetails';
import { AlertList } from 'components/molecules/AlertList';
import { TemplateAuditModal } from 'components/templates/TemplateAuditModal';
import TemplatePageDefault from 'components/templates/TemplatePageDefault';
import { ARGENT, BLACK } from 'config/appColors';
import {
  getSupplierDetails,
  selectSupplierDetails,
} from 'domains/supplier/state/details/slice';
import { getCertificateDetailsPathname } from 'utils/getCertificateDetailsPathname';
import { getCertificateDraftFormPathname } from 'utils/getCertificateDraftFormPathname';
import { getSupplierDetailsTitle } from 'utils/getSupplierDetailsTitle';

import { useUpdateCertificateDraftMutation } from '../../state/createCertificateDraft/api';
import { RequestModeEnum } from '../../state/createCertificateDraft/types';

const ContentWrapper = styled('div')`
  padding: 4rem 10.5rem;
`;

const StyledCard = styled(Card)`
  max-width: 980px;
  width: 100%;
  margin: 0 auto;

  .MuiDialogActions-root {
    padding-top: 16px;
    border-top: 1px ${ARGENT} solid;
  }
`;

const StyledLoaderContainer = styled('div')`
  padding: 30px 0 10px;
  font-weight: 400;
  text-align: center;
  color: ${alpha(BLACK, 0.7)};
`;

export const PageCertificateDraftUpdate: FC = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { supplierId, certificateId } =
    useParams<{ supplierId: string; certificateId: string }>();
  const [t] = useTranslation('certificateDraft');
  const selectedSupplier = useSelector(selectSupplierDetails);
  const titleDetails = getSupplierDetailsTitle(selectedSupplier);
  const [isLoaderHidden, setIsLoaderHidden] = useState(false);

  useEffect(() => {
    if (!selectedSupplier) {
      dispatch(getSupplierDetails({ supplierId }));
    }
  }, [selectedSupplier, supplierId, dispatch]);

  const title = (
    <ModalTitleWithDetails
      title={t('Certificate Update')}
      details={titleDetails}
    />
  );

  const { updateCertificateDraft, error } = useUpdateCertificateDraftMutation();

  const contentComponent = (
    <>
      {isLoaderHidden ? (
        error && error.length > 0 && <AlertList alerts={error} />
      ) : (
        <StyledLoaderContainer>
          <p>{t('We are preparing the certificate for the update.')}</p>
          <LoaderIcon />
          <p>{t('Processing ...', { ns: 'components' })}</p>
        </StyledLoaderContainer>
      )}
    </>
  );

  const handleClose = () => {
    const pathname = getCertificateDetailsPathname({
      supplierId,
      certificateId,
    });
    push({ pathname });
  };

  useEffect(() => {
    updateCertificateDraft({
      mode: RequestModeEnum.UPDATE,
      certificateId,
    })
      .then((certificateDraftId) => {
        push(
          getCertificateDraftFormPathname({ supplierId, certificateDraftId })
        );
      })
      .catch(() => {
        setIsLoaderHidden(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TemplatePageDefault hidePageHeader>
      <ContentWrapper>
        <StyledCard>
          <TemplateAuditModal
            title={title}
            contentComponent={contentComponent}
            nextAction={handleClose}
            noNextArrow
            nextColor="info"
            nextLabel={t('Close', { ns: 'components' })}
            showCloseButton={false}
            disableNext={!isLoaderHidden}
          />
        </StyledCard>
      </ContentWrapper>
    </TemplatePageDefault>
  );
};
