import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { alpha } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import WarningIcon from 'components/atoms/WarningIcon';
import {
  BLACK,
  ERROR as ERROR_COLOR,
  INFO as INFO_COLOR,
  WARNING as WARNING_COLOR,
} from 'config/appColors';

import {
  StyledButton,
  StyledCloseIcon,
  StyledDescription,
  StyledIconWrapper,
  StyledTitle,
  StyledTitleWrapper,
  StyledWrapper,
} from './styled';
import { BannerProps, BannerVariantEnum } from './types';

export const Banner: FC<BannerProps> = (props) => {
  const {
    variant = BannerVariantEnum.DEFAULT,
    customTitle,
    description,
    hideCloseButton = false,
    actionButtonLabel,
    actionButtonClickHandler,
    customIcon,
    hideIcon = false,
  } = props;
  const [t] = useTranslation('components');
  const [open, setOpen] = useState(true);

  const closeBanner = () => {
    setOpen(false);
  };

  const bannerVariants = {
    [BannerVariantEnum.INFO]: {
      title: t('Info'),
      color: INFO_COLOR.LIGHT,
      icon: <InfoIcon />,
    },
    [BannerVariantEnum.WARNING]: {
      title: t('Warning'),
      color: WARNING_COLOR.MAIN,
      icon: <WarningIcon />,
    },
    [BannerVariantEnum.ERROR]: {
      title: t('Error'),
      color: ERROR_COLOR.LIGHT,
      icon: <ErrorIcon />,
    },
    [BannerVariantEnum.DEFAULT]: {
      title: customTitle,
      color: alpha(BLACK, 0.35),
      icon: customIcon,
    },
  };

  const { color, icon, title } = bannerVariants[variant];

  const header = (
    <StyledTitleWrapper>
      {!hideIcon && (
        <StyledIconWrapper $color={color}>
          {customIcon || icon}
        </StyledIconWrapper>
      )}
      <StyledTitle color={color}>{customTitle || title}</StyledTitle>
    </StyledTitleWrapper>
  );

  return open ? (
    <StyledWrapper $borderColor={color}>
      {(customTitle || variant !== BannerVariantEnum.DEFAULT) && header}
      {!hideCloseButton && <StyledCloseIcon onClick={closeBanner} />}
      <StyledDescription $color={color}>{description}</StyledDescription>
      {actionButtonLabel && actionButtonClickHandler && (
        <StyledButton
          onClick={actionButtonClickHandler}
          variant="contained"
          color="info"
        >
          {actionButtonLabel}
        </StyledButton>
      )}
    </StyledWrapper>
  ) : null;
};
