import { alpha, styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import Button, { ButtonProps } from 'components/atoms/Button';
import { BLACK } from 'config/appColors';

const StyledButton = styled(Button)`
  min-width: 118px;
  width: 100%;
  justify-content: flex-start;
  font-size: 1.6rem;
  font-weight: 400;
  padding: 6px 16px;
  letter-spacing: 0.015rem;
  color: ${alpha(BLACK, 0.7)};
  text-transform: none;
  background: 0;

  &:hover {
    background: 0;
  }
`;

const StyledIconWrapper = styled('span')`
  display: flex;
  margin-right: 8px;
  > svg {
    font-size: 2rem;
  }
`;

interface DropdownMenuItemProps extends ButtonProps {
  icon?: ReactNode;
}

export const DropdownMenuItem: FC<DropdownMenuItemProps> = (props) => {
  const { icon, children, ...restProps } = props;

  return (
    <StyledButton {...restProps}>
      {icon && <StyledIconWrapper>{icon}</StyledIconWrapper>}
      {children}
    </StyledButton>
  );
};

export default DropdownMenuItem;
