import React, { FC, useEffect, useRef } from 'react';

import { DateFilter, UseFiltersAndSorting } from 'domains/adminReports/types';
import { useMemoizedDebounce } from 'hooks/useMemoizedDebounce';
import { Nullable } from 'types';
import { PLAIN_DATE_REGEX_WITH_DOTS } from 'utils/regex/date';

import { StyledDateMaskedInput } from '../../atoms/StyledMaskedDateInput';

type DateFilterCellProps = DateFilter & UseFiltersAndSorting;

export const DateFilterCell: FC<DateFilterCellProps> = ({
  filterKey,
  filters,
  setFilterValue,
}) => {
  const ref = useRef<Nullable<HTMLInputElement>>(null);
  const value = filters[filterKey];

  const handleDateChange = (newDate: string) => {
    if (PLAIN_DATE_REGEX_WITH_DOTS.test(newDate) || newDate === '') {
      setFilterValue({ filterKey, value: newDate });
    }
  };

  const debouncedHandleDateChange = useMemoizedDebounce(handleDateChange, 500, [
    filters,
  ]);

  useEffect(() => {
    if (ref.current?.value && value === '') {
      ref.current.value = '';
    }
  }, [value, ref]);

  return (
    <StyledDateMaskedInput
      inputRef={ref}
      defaultValue={value}
      onChange={debouncedHandleDateChange}
    />
  );
};
