import { alpha, styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Banner, BannerVariantEnum } from 'components/molecules/Banner';
import { BLACK } from 'config/appColors';
import { ExceptionalCircumstance, InfoBox } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

interface ExceptionalCircumstanceBoxProps {
  data: InfoBox;
  exceptionalCircumstance: ExceptionalCircumstance;
}

const StyledDescription = styled('span')`
  color: ${alpha(BLACK, 0.7)};
  display: block;
`;

const StyledType = styled(StyledDescription)`
  margin-bottom: 10px;
`;

export const ExceptionalCircumstanceBox: FC<ExceptionalCircumstanceBoxProps> =
  ({ data, exceptionalCircumstance }) => {
    const { props } = data;
    const [t] = useTranslation('components');

    return (
      <Banner
        variant={BannerVariantEnum.WARNING}
        customTitle={getTranslatedValue(props.headerText)}
        description={
          <>
            <StyledType>
              {`${t('Type')}: ${exceptionalCircumstance?.type.name}`}
            </StyledType>
            <StyledDescription>
              {exceptionalCircumstance?.description}
            </StyledDescription>
          </>
        }
        hideIcon
        hideCloseButton
      />
    );
  };
