import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import './index.css';
import App from './App';
import { forceMockHeader as mockHeader } from './mock/constants';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { isAnyMock } from './utils/environments';

const persistor = persistStore(store);

const mockWorker = isAnyMock
  ? (async () => {
      const { worker } = await import('./mock');
      await worker.start({
        onUnhandledRequest: ({ headers }, print) => {
          const forceMockHeader = Object.keys(mockHeader)[0];
          if (headers.get(forceMockHeader)) {
            print.warning();
          }
        },
      });
    })()
  : Promise.resolve();

mockWorker.then(() => {
  const container = document.getElementById('root');
  const root = createRoot(container as HTMLElement);
  root.render(
    <StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <App />
        </PersistGate>
      </Provider>
    </StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
