import { alpha, styled } from '@mui/material';
import React, { FC, ReactElement, SyntheticEvent } from 'react';
import { FileRejection } from 'react-dropzone';

import { Button } from 'components/atoms/Button';
import { RemovableFileLabel } from 'components/atoms/RemovableFileLabel';
import {
  DEFAULT_MAX_FILE_SIZE,
  ErrorTranslation,
  ExtendedFile,
  FileUploadRenderer,
} from 'components/molecules/FileUploadRenderer';
import { BLACK, ERROR_1 } from 'config/appColors';

export interface FileUploadProps {
  maxFileSize?: number; // bytes
  uploadButtonText?: string;
  maxFiles?: number;
  fileTypes?: string | string[];
  onChange?: (files: File[]) => void;
  onFileRejection?: (errors: FileRejection[]) => void;
  errorTranslations?: ErrorTranslation;
  renderErrors?: boolean;
  hideRemoveButtons?: boolean;
}

const StyledUploadButton = styled(Button)`
  && {
    padding: 5px 10px;
    font-size: 1.3rem;
    line-height: 1.69;
    letter-spacing: 0.046rem;
  }
`;
const StyledFileList = styled('ul')<{ $multipleFiles: boolean }>`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ $multipleFiles }) => ($multipleFiles ? '1.2rem 0 0' : '0')};
  padding: 0;
  list-style: none;
`;
const StyledFileListItem = styled('li')`
  margin-right: 1.6rem;
  display: flex;
  align-items: center;

  &:before {
    margin: 0 9px;
    content: '•';
    color: ${alpha(BLACK, 0.7)};
  }

  &:last-child {
    margin-right: 0;
  }
`;

const StyledFile = styled(RemovableFileLabel)`
  && {
    word-break: break-all;
  }
`;
const StyledErrorContainer = styled('div')`
  p {
    font-size: 1.2rem;
    color: ${ERROR_1};
  }
`;

export const FileUpload: FC<FileUploadProps> = (props): ReactElement => {
  const {
    maxFileSize = DEFAULT_MAX_FILE_SIZE,
    maxFiles = 1,
    fileTypes,
    uploadButtonText = 'click here to upload.',
    onChange,
    errorTranslations,
    onFileRejection,
    renderErrors = true,
    hideRemoveButtons = false,
  } = props;
  const multipleFiles = maxFiles > 1;

  const handleErrors = (fileRejection: FileRejection[]) => {
    const rejections = fileRejection[0]?.errors || [];
    return rejections.map(({ code, message }) => {
      const translation = errorTranslations?.[code];
      return translation || message;
    });
  };

  const handleFilesChange = (files: ExtendedFile[]) => {
    if (onChange) {
      const rawFiles = files.map(({ file }) => file);
      onChange(rawFiles);
    }
  };

  const renderContent = (
    files: ExtendedFile[],
    removeFile: (fileId: string, ev: SyntheticEvent) => void
  ) => (
    <>
      {files.length < maxFiles && (
        <StyledUploadButton variant="contained" color="info">
          {uploadButtonText}
        </StyledUploadButton>
      )}
      <StyledFileList $multipleFiles={multipleFiles}>
        {files.map(({ id, file: { name } }) => (
          <StyledFileListItem key={id}>
            <StyledFile
              name={name}
              onFileRemove={(ev) => removeFile(id, ev)}
              showRemoveButton={!hideRemoveButtons}
            />
          </StyledFileListItem>
        ))}
      </StyledFileList>
    </>
  );

  return (
    <FileUploadRenderer
      fileTypes={fileTypes}
      maxFileSize={maxFileSize}
      maxMultipleFiles={maxFiles}
      multipleFiles={multipleFiles}
      onFilesChange={handleFilesChange}
      onErrors={onFileRejection}
      render={({ files, errors, openFileBrowser, removeFile }) => {
        const preparedErrors = handleErrors(errors);
        const fileUploadAction =
          files.length === maxFiles ? undefined : openFileBrowser;

        return (
          <div
            onClick={fileUploadAction}
            onKeyDown={fileUploadAction}
            role="button"
            tabIndex={0}
          >
            {renderContent(files, removeFile)}
            {renderErrors && preparedErrors.length > 0 && (
              <StyledErrorContainer>
                {preparedErrors.map((err) => (
                  <p key={err}>{err}</p>
                ))}
              </StyledErrorContainer>
            )}
          </div>
        );
      }}
    />
  );
};

export default FileUpload;
