import React, { FC, ReactElement } from 'react';

import { LoaderStatusEnum } from 'types';

import {
  TemplateResponseStatusProps,
  TemplateResponseStatusVariantEnum,
} from './types';
import { TemplateResponseStatusVariantOne } from './VariantOne';
import { TemplateResponseStatusVariantTwo } from './VariantTwo';

export const TemplateResponseStatus: FC<TemplateResponseStatusProps> = (
  props
): ReactElement => {
  const {
    title,
    type = LoaderStatusEnum.LOADING,
    description,
    children,
    variant = TemplateResponseStatusVariantEnum.ONE,
    textList,
  } = props;

  return (
    <>
      {variant === TemplateResponseStatusVariantEnum.ONE && (
        <TemplateResponseStatusVariantOne {...props}>
          {children}
        </TemplateResponseStatusVariantOne>
      )}
      {variant === TemplateResponseStatusVariantEnum.TWO && (
        <TemplateResponseStatusVariantTwo
          type={type}
          title={title}
          textList={textList}
          description={description}
        />
      )}
    </>
  );
};

export default TemplateResponseStatus;
