import { alpha, styled } from '@mui/material';

import { BLACK, ERROR } from 'config/appColors';

const variants = {
  regular: alpha(BLACK, 0.7),
  error: ERROR.LIGHT,
};

interface StyledCustomHeadingProps {
  $variant?: keyof typeof variants;
}

const StyledCustomHeading = styled('p')<StyledCustomHeadingProps>`
  margin-top: 0;
  font-weight: bold;
  color: ${({ $variant = 'error' }) => variants[$variant]};
`;

StyledCustomHeading.displayName = 'StyledCustomHeading';

export { StyledCustomHeading };
