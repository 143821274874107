import { alpha, styled } from '@mui/material';
import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { FC } from 'react';

import { BLACK } from 'config/appColors';

interface CheckboxProps extends MuiCheckboxProps {
  label?: string;
  value?: string;
}

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 2px;
  display: flex;
  align-items: flex-start;
`;

const StyledCheckbox = styled(MuiCheckbox)`
  margin-top: -8px;
  svg {
    font-size: 2.5rem;
  }
`;

const Label = styled('span')`
  color: ${alpha(BLACK, 0.7)};
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.015rem;
  font-weight: 400;
`;

export const Checkbox: FC<CheckboxProps> = ({ label, value, ...props }) => (
  <StyledFormControlLabel
    value={value}
    className={props.className}
    control={<StyledCheckbox {...props} color="primary" />}
    label={<Label>{label}</Label>}
    labelPlacement="end"
  />
);
