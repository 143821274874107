import { alpha, styled } from '@mui/material';
import React, { FC, useMemo } from 'react';

import { StepDescription } from 'components/atoms/StepDescription';
import { ActionInformationSection } from 'components/organisms/ActionInformationSection';
import { BLACK } from 'config/appColors';
import { useReasonStep } from 'hooks/draft/useReasonStep';
import { getStatus } from 'utils/getStatus';
import { getTranslatedValue } from 'utils/getTranslatedValue';
import { isAnyLoading } from 'utils/isAnyLoading';

import { useCertificateDraftDetailsQuery } from '../../../state/certificateDraftDetails/api';
import { useSetCertificateDraftActionCommentMutation } from '../../../state/setActionComment/api';
import { useCertificateDraftSetUpdateReasonMutation } from '../../../state/setUpdateReason/api';
import { FormReasonStep } from '../../../types';
import { TemplateCertificateDraftStep } from '../../templates/TemplateCertificateDraftStep';

type FormReasonProps = FormReasonStep['props'] & { certificateDraftId: string };

const StyledReasonHint = styled(StepDescription)`
  margin-top: 10px;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: 0.017rem;
  color: ${alpha(BLACK, 0.5)};
  display: block;
`;

export const FormReason: FC<FormReasonProps> = (props) => {
  const {
    actionReasonOptions,
    updateOptions,
    certificateDraftId,
    backAction,
    hintUpdateReasonDescription,
    nextAction,
    labelUpdateReasonDescription,
    labelUpdateReasonSelect,
  } = props;
  const {
    certificateDraftDetails: draftDetails,
    status: certificateDraftDetailsStatus,
  } = useCertificateDraftDetailsQuery({ certificateDraftId });

  const {
    updateReason,
    updateDescription,
    actionReason,
    actionComment,
    isAdmin,
    handleCommentChange,
    handleReasonChange,
    handleAdminReasonChange,
    handleAdminCommentChange,
    disableNextButton,
    actionOptions,
    updateReasonOptions,
  } = useReasonStep({ actionReasonOptions, updateOptions, draftDetails });

  const {
    error: updateReasonError = [],
    setCertificateDraftUpdateReason: setUpdateReasonRequest,
    status: updateReasonStatus,
  } = useCertificateDraftSetUpdateReasonMutation();
  const {
    error: setActionReasonError = [],
    setCertificateDraftActionComment: setActionCommentRequest,
    status: actionCommentStatus,
  } = useSetCertificateDraftActionCommentMutation();

  const handleNextClick = (goNextStep: () => void) =>
    setUpdateReasonRequest({
      certificateDraftId,
      reason: {
        updateReason,
        updateDescription,
      },
    })
      .then(() =>
        isAdmin
          ? setActionCommentRequest({
              certificateDraftId,
              actionComment: {
                reason: actionReason,
                comment: actionComment,
              },
            })
          : Promise.resolve()
      )
      .then(goNextStep);

  const errorMessages = useMemo(
    () => [...updateReasonError, ...setActionReasonError],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(updateReasonError), JSON.stringify(setActionReasonError)]
  );

  const nextButtonStatus = getStatus(
    isAnyLoading(actionCommentStatus, updateReasonStatus)
  );

  const commentLabel = (
    <>
      {getTranslatedValue(labelUpdateReasonDescription)}
      <StyledReasonHint
        variant="caption"
        content={getTranslatedValue(hintUpdateReasonDescription)}
      />
    </>
  );

  return (
    <TemplateCertificateDraftStep
      contentStatus={certificateDraftDetailsStatus}
      nextButtonStatus={nextButtonStatus}
      backAction={backAction}
      nextAction={nextAction}
      disableNext={disableNextButton}
      onNextClick={handleNextClick}
      error={errorMessages}
    >
      <ActionInformationSection
        adminReason={updateReason}
        adminComment={updateDescription}
        description={getTranslatedValue(labelUpdateReasonSelect)}
        commentLabel={commentLabel}
        handleAdminReasonChange={handleReasonChange}
        handleAdminCommentChange={handleCommentChange}
        adminReasonOptions={updateReasonOptions}
        textareaRows={6}
        hideHeading
      />

      {isAdmin && (
        <ActionInformationSection
          adminReason={actionReason}
          adminComment={actionComment}
          handleAdminReasonChange={handleAdminReasonChange}
          handleAdminCommentChange={handleAdminCommentChange}
          adminReasonOptions={actionOptions}
        />
      )}
    </TemplateCertificateDraftStep>
  );
};
