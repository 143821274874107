import React, { FC, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import { PermissionGate } from 'components/molecules/PermissionGate';
import apiClient from 'utils/apiClient';
import { off, on, trigger } from 'utils/customEvents';
import { REDIRECT_TO_IFRAME_ERROR_EVENT } from 'utils/customEvents/eventNames';
import { isFullMock } from 'utils/environments';
import isIframe from 'utils/isIframe';

import endpoints from '../../../config/endpoints';
import WidgetRoutes from '../../../config/routes';
import { useInvalidateTagsOnTabClick } from '../../../hooks/useInvalidateTagsOnTabClick';
import { WidgetAuditAppointmentDetails } from '../../organisms/WidgetAuditAppointmentDetails';
import { WidgetAuditAppointments } from '../../organisms/WidgetAuditAppointments';
import { WidgetAuditDetails } from '../../organisms/WidgetAuditDetails';
import { WidgetAuditDraftForm } from '../../organisms/WidgetAuditDraftForm';
import { WidgetAuditDraftUpdateDocuments } from '../../organisms/WidgetAuditDraftUpdateDocuments';
import { WidgetAuditDraftUpdateDocumentsInit } from '../../organisms/WidgetAuditDraftUpdateDocumentsInit';
import { WidgetAuditFileUploadAmendmentUpload } from '../../organisms/WidgetAuditFileUploadAmendmentUpload';
import { WidgetAuditFileUploadRegularUpload } from '../../organisms/WidgetAuditFileUploadRegularUpload.tsx';
import { WidgetAudits } from '../../organisms/WidgetAudits';
import { WidgetAuditsCertificates } from '../../organisms/WidgetAuditsCertificates';
import { WidgetCertificateDetails } from '../../organisms/WidgetCertificateDetails';
import { WidgetCertificateDraftCreate } from '../../organisms/WidgetCertificateDraftCreate';
import { WidgetCertificateDraftForm } from '../../organisms/WidgetCertificateDraftForm';
import { WidgetCertificateDraftUpdate } from '../../organisms/WidgetCertificateDraftUpdate';
import { WidgetCertificates } from '../../organisms/WidgetCertificates';
import { WidgetDiary } from '../../organisms/WidgetDiary';
import { WidgetErrorScreen } from '../../organisms/WidgetErrorScreen';
import { WidgetErrorPayload } from '../../organisms/WidgetErrorScreen/types';

const GlobalStyle = createGlobalStyle`
  &&&.MuiDialog-root,
  &&&.MuiBackdrop-root {
    left: 0 !important;
    top: 0 !important;
  }
`;

export const PageWidgetBase: FC = () => {
  const [isCookieSet, setIsCookieSet] = useState(false);
  const history = useHistory();
  useInvalidateTagsOnTabClick();
  const verifyToken = () =>
    isFullMock ? Promise.resolve() : apiClient.post(endpoints.VERIFY_TOKEN);

  const setCookieRequest = async () => {
    try {
      await verifyToken();
      setIsCookieSet(true);
    } catch (error) {
      trigger(REDIRECT_TO_IFRAME_ERROR_EVENT);
    }
  };

  useEffect(() => {
    if (isIframe) {
      document.body.classList.add('iframe');
      setTimeout(() => setCookieRequest(), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleRedirectToError = (ev: CustomEventInit<WidgetErrorPayload>) => {
      const type = ev.detail?.type;
      return history.push({
        pathname: WidgetRoutes.ERROR_PAGE,
        state: { type },
      });
    };
    if (isIframe) {
      on<WidgetErrorPayload>(
        REDIRECT_TO_IFRAME_ERROR_EVENT,
        handleRedirectToError
      );
    }
    return () => {
      off<WidgetErrorPayload>(
        REDIRECT_TO_IFRAME_ERROR_EVENT,
        handleRedirectToError
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GlobalStyle />
      {isCookieSet ? (
        <Switch>
          <Route
            exact
            path={WidgetRoutes.AUDIT_APPOINTMENT_DETAILS}
            component={WidgetAuditAppointmentDetails}
          />
          <Route
            exact
            path={WidgetRoutes.AUDIT_APPOINTMENTS}
            component={WidgetAuditAppointments}
          />
          <Route exact path={WidgetRoutes.CB_DIARY} component={WidgetDiary} />
          <Route exact path={WidgetRoutes.AUDITS} component={WidgetAudits} />
          <Route
            exact
            path={WidgetRoutes.AUDIT_DETAILS}
            component={WidgetAuditDetails}
          />
          <Route
            exact
            path={WidgetRoutes.AUDIT_FILE_UPLOAD_AMENDMENT_UPLOAD}
            component={WidgetAuditFileUploadAmendmentUpload}
          />
          <Route
            exact
            path={WidgetRoutes.AUDIT_FILE_UPLOAD_REGULAR_UPLOAD}
            component={WidgetAuditFileUploadRegularUpload}
          />
          <Route
            path={[
              WidgetRoutes.AUDIT_DRAFT_FORM,
              WidgetRoutes.AUDIT_AMENDMENT_FORM,
            ]}
            exact
            component={WidgetAuditDraftForm}
          />
          <Route
            exact
            path={WidgetRoutes.AUDIT_DRAFT_UPDATE_DOCUMENTS_INIT}
            component={WidgetAuditDraftUpdateDocumentsInit}
          />
          <Route
            exact
            path={WidgetRoutes.AUDIT_DRAFT_UPDATE_DOCUMENTS}
            component={WidgetAuditDraftUpdateDocuments}
          />

          <Route
            exact
            path={WidgetRoutes.CERTIFICATES}
            component={WidgetCertificates}
          />
          <Route
            exact
            path={WidgetRoutes.CERTIFICATE_DETAILS}
            component={WidgetCertificateDetails}
          />
          <Route
            exact
            path={WidgetRoutes.CERTIFICATE_DRAFT_FORM}
            component={WidgetCertificateDraftForm}
          />
          <Route
            exact
            path={WidgetRoutes.CERTIFICATE_DRAFT_CREATE}
            component={WidgetCertificateDraftCreate}
          />
          <Route
            exact
            path={WidgetRoutes.CERTIFICATE_DRAFT_UPDATE}
            component={WidgetCertificateDraftUpdate}
          />
          <Route
            exact
            path={WidgetRoutes.FILTERABLE_AUDITS_CERTIFICATES}
            component={() => (
              <PermissionGate requiredValueToPass="WidgetAuditsCertificates">
                <WidgetAuditsCertificates />
              </PermissionGate>
            )}
          />
          <Route path={WidgetRoutes.ERROR_PAGE} component={WidgetErrorScreen} />
        </Switch>
      ) : null}
    </>
  );
};
