import { alpha, styled } from '@mui/material';
import React from 'react';

import { BLACK } from 'config/appColors';

import { AdminTableCell } from '../../../types';
import { StyledPlainText as StyledPlainTextBase } from '../../atoms/StyledPlainText';

const StyledPlainText = styled(StyledPlainTextBase)<{ $color?: string }>`
  color: ${({ $color }) => $color};
  span {
    color: ${alpha(BLACK, 0.7)};
  }
`;

const StyledList = styled('ul')`
  padding-left: 25px;
  margin: 0;
`;

export const ResultCell: AdminTableCell = ({ result: { overall, levels } }) => (
  <>
    <StyledPlainText $color={overall.color}>
      {overall.text}
      {overall.percentage && <span> ({overall.percentage})</span>}
    </StyledPlainText>
    {levels && levels.length > 0 && (
      <StyledList>
        {levels.map(({ id, text, percentage }) => (
          <li key={id}>
            <StyledPlainTextBase>
              {text}
              {percentage && <> ({percentage})</>}
            </StyledPlainTextBase>
          </li>
        ))}
      </StyledList>
    )}
  </>
);
