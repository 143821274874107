import React, { FC, forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

import { TextField, TextFieldProps } from '../TextField';

interface MaskProps {
  onChange: (value: string) => void;
}

const Mask = forwardRef<HTMLInputElement, MaskProps>(
  ({ onChange, ...restProps }, innerRef) => (
    <IMaskInput
      {...restProps}
      mask="00.00.0000"
      definitions={{
        '0': /[0-9]/,
      }}
      inputRef={innerRef}
      onAccept={onChange}
      overwrite
    />
  )
);

interface DateMaskedInputProps extends Omit<TextFieldProps, 'onChange'> {
  onChange?: (value: string) => void;
}

export const DateMaskedInput: FC<DateMaskedInputProps> = ({
  onChange,
  ...props
}) => (
  <TextField
    {...props}
    onChange={(value) => {
      if (typeof value === 'string') {
        onChange?.(value);
      }
    }}
    InputProps={{
      // assertion to any is taken from docs
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      inputComponent: Mask as any,
    }}
  />
);
