import jwtDecode from 'jwt-decode';

import { AccessTokenPayloadType, Nullable } from 'types';

export class AccessToken {
  private static STORAGE_KEY = 'AccessToken';

  static get(): Nullable<string> {
    return sessionStorage.getItem(this.STORAGE_KEY);
  }

  static save(token: string): void {
    return sessionStorage.setItem(this.STORAGE_KEY, token);
  }

  static remove(): void {
    return sessionStorage.removeItem(this.STORAGE_KEY);
  }

  static decode<DecodedToken = AccessTokenPayloadType>(
    token: string
  ): DecodedToken {
    return jwtDecode(token);
  }

  static isAlive(): boolean {
    const token = this.get();
    if (!token) {
      return false;
    }

    try {
      const { exp } = this.decode(token);

      return new Date(exp * 1000) > new Date();
    } catch {
      return false;
    }
  }
}
