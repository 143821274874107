import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { styled } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import React, { FC, ReactElement } from 'react';

import { Button } from 'components/atoms/Button';
import { Heading } from 'components/atoms/Heading';
import { LoaderIcon } from 'components/atoms/LoaderIcon';
import { ModalActions } from 'components/atoms/ModalActions';
import { BLACK, ERROR_1, SUCCESS_1 } from 'config/appColors';
import { LoaderStatusEnum } from 'types';

import { TemplateResponseStatusProps } from './types';

const StyledModalContent = styled(DialogContent)`
  min-height: 340px;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${BLACK};
`;
const StyledModalActions = styled(ModalActions)`
  justify-content: flex-end;
`;
const StyledTitle = styled(Heading)`
  font-weight: 500;
  color: ${BLACK};
  && {
    font-size: 2rem;
  }
`;
const StyledDescription = styled('p')`
  margin: 0;
  font-size: 1.4rem;
  font-weight: 400;
`;
const StyledExtraDescription = styled('span')`
  display: block;
  margin-top: 25px;
`;
const StyledLoader = styled(LoaderIcon)`
  margin: 30px 0;
`;
const StyledSuccessIcon = styled(CheckIcon)`
  font-size: 4.3rem;
  margin: 30px 0 26px;
  color: ${SUCCESS_1};
`;
const StyledErrorIcon = styled(ErrorIcon)`
  font-size: 4.3rem;
  margin: 30px 0 26px;
  color: ${ERROR_1};
`;

export const TemplateResponseStatusVariantOne: FC<TemplateResponseStatusProps> =
  (props): ReactElement => {
    const {
      title,
      closeAction,
      type = LoaderStatusEnum.LOADING,
      description,
      extraDescription,
      renderCustomClose,
      hideCloseButton,
      children,
    } = props;
    const renderIcon = () => {
      switch (type) {
        case LoaderStatusEnum.SUCCESS: {
          return <StyledSuccessIcon />;
        }
        case LoaderStatusEnum.ERROR: {
          return <StyledErrorIcon />;
        }
        default: {
          return <StyledLoader />;
        }
      }
    };

    return (
      <>
        {type === LoaderStatusEnum.IDLE && children}
        {type !== LoaderStatusEnum.IDLE && (
          <>
            <StyledModalContent>
              <StyledTitle variant="h3">{title}</StyledTitle>
              {renderIcon()}
              {description && (
                <StyledDescription>
                  {description}
                  {extraDescription && (
                    <StyledExtraDescription>
                      {extraDescription}
                    </StyledExtraDescription>
                  )}
                </StyledDescription>
              )}
              {renderCustomClose &&
                closeAction &&
                renderCustomClose(closeAction)}
            </StyledModalContent>
            {!renderCustomClose && !hideCloseButton && (
              <StyledModalActions>
                <Button variant="text" color="secondary" onClick={closeAction}>
                  Close
                </Button>
              </StyledModalActions>
            )}
          </>
        )}
      </>
    );
  };

export default TemplateResponseStatusVariantOne;
