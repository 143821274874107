import { CssBaseline, ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { ErrorBoundary } from 'components/molecules/ErrorBoundary';
import i18next from 'i18n';
import theme from 'themes/main';
import { off, on } from 'utils/customEvents';
import isIframe from 'utils/isIframe';
import { PostMessage } from 'utils/postMessage';

import { loginWithAccessToken, logout } from './domains/user/state/auth/slice';
import AppRoutes from './Routes';
import SnackbarProvider from './Snackbars';
import { LOGOUT_EVENT } from './utils/customEvents/eventNames';

function App(): JSX.Element {
  const dispatch = useDispatch();

  const receiveMessage = (event: MessageEvent) => {
    const { accessToken } = event.data;
    if (accessToken) {
      dispatch(loginWithAccessToken(accessToken));
    }
  };

  useEffect(() => {
    const logoutAction = () => dispatch(logout());
    on(LOGOUT_EVENT, logoutAction);

    return () => {
      off(LOGOUT_EVENT, logoutAction);
    };
  }, [dispatch]);

  // TODO: once we get rid of iframe things useEffect below should be removed
  useEffect(() => {
    if (isIframe) {
      PostMessage.isContentLoaded();
      window.addEventListener('message', receiveMessage, false);
    }
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <I18nextProvider i18n={i18next}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              <CssBaseline />
              <ErrorBoundary>
                <AppRoutes />
              </ErrorBoundary>
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </I18nextProvider>
    </Router>
  );
}

export default App;
