import { styled } from '@mui/material';
import React, { FC } from 'react';

import { Flag } from 'components/atoms/Flag';
import { METALLIC_BLUE } from 'config/appColors';
import { Document } from 'types';

const StyledDownloadButton = styled('button')`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 0;
  background: 0;
  width: 100%;
  text-align: left;

  * + * {
    margin-left: 9px;
  }
`;

const StyledLabel = styled('span')`
  text-decoration: underline;
  color: ${METALLIC_BLUE};
  width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    text-decoration: none;
  }
`;

interface DownloadFileButtonProps {
  document: Document;
  onClick: (documentUuid: string, fileName: string) => void;
}

export const DownloadFileButton: FC<DownloadFileButtonProps> = (props) => {
  const { onClick, document } = props;
  const { fileName, displayName, language, uuid } = document;

  const title = `${displayName || fileName}${
    (displayName && fileName && ` (${fileName})`) ?? ''
  }`;

  return (
    <StyledDownloadButton
      type="button"
      onClick={() => onClick(uuid, fileName)}
      title={title}
    >
      <Flag country={language} noFallbackComponent />
      <StyledLabel>{title}</StyledLabel>
    </StyledDownloadButton>
  );
};
