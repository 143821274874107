import { styled } from '@mui/material';
import React from 'react';

import { StatusIndicator } from 'components/atoms/StatusIndicator';
import { ERROR } from 'config/appColors';
import { AuditStatusEnum } from 'types';

import { yiiUrls } from '../../../config/yiiUrls';
import { AdminTableCell } from '../../../types';
import { StyledLink } from '../../atoms/StyledLink';

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

export const AuditIdCell: AdminTableCell = ({
  auid,
  status,
  supplier,
  uuid,
}) => (
  <StyledContainer>
    <StyledLink
      target="_blank"
      rel="noopener noreferrer"
      to={{ pathname: yiiUrls.AUDIT_DETAILS(supplier.uuid, uuid) }}
    >
      {auid}
    </StyledLink>
    {status.id === AuditStatusEnum.DELETED && (
      <StatusIndicator label={status.name} bgColor={ERROR.LIGHT} />
    )}
  </StyledContainer>
);
