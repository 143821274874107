import { alpha } from '@mui/material';
import React, { FC } from 'react';

import { StatusIndicator } from 'components/atoms/StatusIndicator';
import { BLACK, CANDY_PINK, INFO, SUCCESS_1, WHITE } from 'config/appColors';
import { GeneralDetails, ProcessStatus } from 'types';

export const BACKGROUND_COLORS = {
  [ProcessStatus.CANCELLED]: CANDY_PINK,
  [ProcessStatus.COMPLETED]: SUCCESS_1,
  [ProcessStatus.OPEN]: INFO.LIGHT,
  [ProcessStatus.DEACTIVATED]: alpha(BLACK, 0.3),
};

interface StatusIndicatorProps {
  status: GeneralDetails<ProcessStatus>;
}

export const ProcessStatusIndicator: FC<StatusIndicatorProps> = (props) => {
  const { status } = props;

  return (
    <StatusIndicator
      label={status?.name}
      bgColor={BACKGROUND_COLORS[status?.id]}
      textColor={WHITE}
    />
  );
};
