import { alpha, styled } from '@mui/material';

import { BLACK } from 'config/appColors';

const StyledPlainText = styled('p')`
  margin: 0;
  color: ${alpha(BLACK, 0.7)};
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.017rem;
`;

StyledPlainText.displayName = 'StyledPlainText';

export { StyledPlainText };
