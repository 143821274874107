import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { alpha, styled } from '@mui/material';
import MuiListSubheader from '@mui/material/ListSubheader';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import React, { ElementType, FC, ReactElement } from 'react';

import { BLACK, COBALT_BLUE, TEXT } from 'config/appColors';
import { GroupedOption, SelectOption } from 'types';

export interface SelectProps extends MuiSelectProps {
  options: (SelectOption | GroupedOption)[];
  icon?: ElementType;
}

const StyledMuiSelect = styled(MuiSelect)`
  position: relative;

  svg {
    margin-right: 10px;
  }

  && .MuiSelect-select {
    padding: 1.1rem 3.7rem 1.1rem 1.2rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    letter-spacing: 0.015rem;
    color: ${({ value }) => (value ? TEXT.PRIMARY : alpha(BLACK, 0.5))};
  }

  && .MuiOutlinedInput-notchedOutline {
    border-color: ${alpha(BLACK, 0.23)};
    border-width: 1px;
  }

  &.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${TEXT.PRIMARY};
    }
  }

  &.Mui-disabled {
    .MuiSelect-select {
      -webkit-text-fill-color: ${TEXT.DISABLED}; // necessary because this has precedence over color and MUI uses this
    }
    .MuiOutlinedInput-notchedOutline {
      border-style: dotted;
    }
  }

  .MuiSelect-icon {
    width: 2.7rem;
    height: 2.7rem;
    top: calc(50% - 1.35rem);
    right: 0;
  }
`;
const StyledMuiMenuItem = styled(MuiMenuItem)`
  && {
    font-size: 1.6rem;
    font-weight: 400;
    color: ${TEXT.PRIMARY};

    &.Mui-selected {
      background: ${alpha(COBALT_BLUE, 0.04)};
    }

    &.Mui-disabled {
      color: ${TEXT.DISABLED};
      background: transparent;
      opacity: 1;
    }
  }
`;
const StyledMuiSubheader = styled(MuiListSubheader)`
  && {
    font-size: 1.4rem;
  }
`;

const menuSx = {
  '&': {
    top: '10px',
  },
};

export const Select: FC<SelectProps> = (props): ReactElement => {
  const { placeholder, options, icon, MenuProps, ...rest } = props;

  const renderOption = ({ label, value }: SelectOption) => (
    <StyledMuiMenuItem value={value} key={value}>
      {label}
    </StyledMuiMenuItem>
  );

  const renderGroup = (opt: GroupedOption) => [
    <StyledMuiSubheader key={opt.label}>{opt.label}</StyledMuiSubheader>,
    opt.options.map((i: SelectOption) => renderOption(i)),
  ];

  return (
    <StyledMuiSelect
      IconComponent={icon || ArrowDropDownIcon}
      displayEmpty={!!placeholder}
      placeholder={placeholder}
      MenuProps={{
        ...MenuProps,
        sx: { ...menuSx, ...MenuProps?.sx },
      }}
      {...rest}
    >
      {placeholder && (
        <StyledMuiMenuItem value="" disabled>
          {placeholder}
        </StyledMuiMenuItem>
      )}
      {options.map((opt) =>
        'options' in opt ? renderGroup(opt) : renderOption(opt)
      )}
    </StyledMuiSelect>
  );
};
