import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DownloadIcon from '@mui/icons-material/Download';
import { alpha, SelectChangeEvent, styled } from '@mui/material';
import React, { FC, ChangeEvent as ReactChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/atoms/Button';
import { LoaderIcon } from 'components/atoms/LoaderIcon';
import { Select } from 'components/atoms/Select';
import { BLACK, WHITE } from 'config/appColors';
import { useDiaryResetSearchPhrase } from 'domains/auditAppointment/hooks/useDiaryResetSearchPhrase';
import { useMemoizedDebounce } from 'hooks/useMemoizedDebounce';
import { useSnackbarNotification } from 'hooks/useSnackbarNotification';
import { LoaderStatusEnum, ProcessStatus } from 'types';
import { getTranslatedStatuses } from 'utils/getTranslatedStatuses';

import { DIARY_MIN_SEARCH_CHARS } from '../../../config/constants';
import { TEMPORARY_MODULES } from '../../../config/temporalConstants';
import {
  exportDiaryAuditRegistrationsList,
  resetFilterAndSortState,
  resetSearchPhrase,
  selectDiaryAuditRegistrationsExportLoading,
  selectDiaryAuditRegistrationsFilter,
  selectSearchMinCharError,
  setFilterState,
} from '../../../state/diaryAuditRegistrations/slice';
import { FilterSearchInput } from '../../atoms/FilterSearchInput';

const StyledLabel = styled('label')`
  display: flex;
  flex-direction: column;
`;

const StyledLabelText = styled('span')`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.015rem;
`;

const StyledFiltersContainer = styled('div')`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  margin-bottom: 45px;
`;

const StyledSelect = styled(Select)`
  height: 40px;
  background: ${WHITE};
`;

const StyledCommonButton = styled(Button)`
  height: 30px;
  font-size: 1.3rem;
  line-height: 2.2rem;
  letter-spacing: 0.046rem;
  font-weight: 500;
`;

const StyledResetButton = styled(StyledCommonButton)`
  margin-top: 20px;
  width: fit-content;
`;

const StyledExportButton = styled(StyledCommonButton)`
  margin-top: 20px;
  margin-left: auto;
  width: 95px;
`;

const StyledLoader = styled(LoaderIcon)`
  width: 20px;
  height: 20px;

  svg {
    color: ${alpha(BLACK, 0.5)};
  }
`;

export const DiaryAuditRegistrationsFilters: FC = () => {
  const [t] = useTranslation('auditAppointment');
  const dispatch = useDispatch();
  const notificationMessage = t('Excel could not be generated');
  const { openSnackbar } = useSnackbarNotification();
  const filter = useSelector(selectDiaryAuditRegistrationsFilter);
  const loading = useSelector(selectDiaryAuditRegistrationsExportLoading);
  const searchTermInputRef = useRef<HTMLInputElement | null>(null);
  const searchMinCharError = useSelector(selectSearchMinCharError);

  useDiaryResetSearchPhrase(
    searchMinCharError,
    resetSearchPhrase,
    searchTermInputRef
  );

  const handleStatusChange = (event: SelectChangeEvent<unknown>) => {
    dispatch(
      setFilterState({
        key: 'status',
        value: event.target.value as ProcessStatus,
      })
    );
  };

  const handleModuleChange = (event: SelectChangeEvent<unknown>) => {
    dispatch(
      setFilterState({ key: 'moduleId', value: event.target.value as string })
    );
  };

  const handleSearchChange = (
    event: ReactChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) =>
    dispatch(
      setFilterState({
        key: 'supplierNameCoidSearchString',
        value: event.target.value.trim(),
      })
    );

  const debouncedHandleChange = useMemoizedDebounce(handleSearchChange);

  const handleResetClick = () => {
    dispatch(resetFilterAndSortState());
    if (searchTermInputRef && searchTermInputRef.current) {
      searchTermInputRef.current.value = '';
    }
  };

  const onExportErrorCallback = () =>
    openSnackbar(notificationMessage, 'error');
  const handleExportClick = () => {
    dispatch(exportDiaryAuditRegistrationsList({ onExportErrorCallback }));
  };

  return (
    <StyledFiltersContainer>
      <StyledLabel>
        <StyledLabelText>{t('Status')}</StyledLabelText>
        <StyledSelect
          data-testid="status"
          icon={ArrowDropDownIcon}
          value={filter.status[0]}
          options={getTranslatedStatuses()}
          onChange={handleStatusChange}
        />
      </StyledLabel>
      <StyledLabel>
        <StyledLabelText>
          {t('Standard/program', { ns: 'components' })}
        </StyledLabelText>
        <StyledSelect
          data-testid="module"
          icon={ArrowDropDownIcon}
          value={filter.moduleId}
          placeholder="---"
          options={TEMPORARY_MODULES}
          onChange={handleModuleChange}
        />
      </StyledLabel>
      <StyledLabel>
        <StyledLabelText>{t('Search Name/COID')}</StyledLabelText>
        <FilterSearchInput
          data-testid="search"
          inputRef={searchTermInputRef}
          placeholder={t('search')}
          defaultValue={filter.supplierNameCoidSearchString}
          onChange={debouncedHandleChange}
          helperText={
            searchMinCharError
              ? t('Please enter at least {{number}} characters', {
                  number: DIARY_MIN_SEARCH_CHARS,
                })
              : ''
          }
          error={searchMinCharError}
          minWidth="200px"
          maxWidth="400px"
        />
      </StyledLabel>
      <StyledResetButton
        variant="contained"
        color="info"
        onClick={handleResetClick}
      >
        {t('Reset')}
      </StyledResetButton>
      <StyledExportButton
        variant="contained"
        color="info"
        endIcon={
          loading === LoaderStatusEnum.LOADING ? (
            <StyledLoader size="small" />
          ) : (
            <DownloadIcon />
          )
        }
        onClick={handleExportClick}
        disabled={loading === LoaderStatusEnum.LOADING}
      >
        {t('export', { ns: 'components' })}
      </StyledExportButton>
    </StyledFiltersContainer>
  );
};
