import { alpha, Card, styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import LoaderIcon from 'components/atoms/LoaderIcon';
import { ModalTitleWithDetails } from 'components/atoms/ModalTitleWithDetails';
import { AlertList } from 'components/molecules/AlertList';
import TemplateAuditModal from 'components/templates/TemplateAuditModal';
import TemplatePageDefault from 'components/templates/TemplatePageDefault';
import { ARGENT, BLACK } from 'config/appColors';
import { useGetAuditDetailsQuery } from 'domains/auditDetails/state/auditDetails/api';
import {
  selectUserCompanyId,
  selectUserIsAdmin,
} from 'domains/user/state/auth/slice';
import { AuditDetailsURLParams } from 'types';
import { getAuditDetailsPathname } from 'utils/getAuditDetailsPathname';
import { getAuditDraftUpdateDocsPathname } from 'utils/getAuditDraftUpdateDocsPathname';
import { getSupplierDetailsTitle } from 'utils/getSupplierDetailsTitle';

import { useCreateAuditDraftViaExistingAuditMutation } from '../../state/auditDraftViaExistingAudit/api';

const ContentWrapper = styled('div')`
  padding: 4rem 10.5rem;
`;

const StyledCard = styled(Card)`
  max-width: 980px;
  width: 100%;
  margin: 0 auto;

  .MuiDialogActions-root {
    padding-top: 16px;
    border-top: 1px ${ARGENT} solid;
  }
`;

const StyledLoaderContainer = styled('div')`
  padding: 30px 0 10px;
  font-weight: 400;
  text-align: center;
  color: ${alpha(BLACK, 0.7)};
`;

export const PageAuditDraftUpdateDocumentsInit: FC = () => {
  const [t] = useTranslation('auditDraft');
  const { supplierId, auditId } = useParams<AuditDetailsURLParams>();
  const { auditDetails } = useGetAuditDetailsQuery({ auditUuid: auditId });
  const {
    createAuditDraft,
    error: createAuditDraftError,
    isError,
    isUninitialized,
  } = useCreateAuditDraftViaExistingAuditMutation();

  const { push, location } = useHistory();
  const userCertificationBodyId = useSelector(selectUserCompanyId);
  const isAdmin = useSelector(selectUserIsAdmin);
  const certificationBodyId = isAdmin
    ? auditDetails?.certificationBody.uuid
    : userCertificationBodyId;

  const handleCloseClick = () =>
    push(getAuditDetailsPathname(supplierId, auditId));

  useEffect(() => {
    if (!certificationBodyId || !isUninitialized) return;

    createAuditDraft({
      mode: 'update_only_documents',
      supplierId,
      certificationBodyId,
      auditId,
    }).then((auditDraftId) => {
      push(
        getAuditDraftUpdateDocsPathname(supplierId, auditDraftId, auditId),
        location.state
      );
    });
  }, [
    auditId,
    certificationBodyId,
    createAuditDraft,
    isUninitialized,
    location.state,
    push,
    supplierId,
  ]);

  const title = (
    <ModalTitleWithDetails
      title={`${t('Update Documents')} ${auditDetails?.module.name || ''}`}
      details={
        auditDetails?.supplier
          ? getSupplierDetailsTitle(auditDetails.supplier)
          : ''
      }
    />
  );

  let component = (
    <StyledLoaderContainer>
      <p>{t('We are preparing the audit for the update.')}</p>
      <LoaderIcon />
      <p>{t('Processing ...', { ns: 'components' })}</p>
    </StyledLoaderContainer>
  );

  if (createAuditDraftError && createAuditDraftError.length > 0) {
    component = <AlertList alerts={createAuditDraftError} />;
  }

  return (
    <TemplatePageDefault hidePageHeader>
      <ContentWrapper>
        <StyledCard>
          <TemplateAuditModal
            title={title}
            contentComponent={component}
            nextLabel={t('Close', { ns: 'components' })}
            disableNext={!isError}
            nextAction={handleCloseClick}
            noNextArrow
            nextColor="info"
          />
        </StyledCard>
      </ContentWrapper>
    </TemplatePageDefault>
  );
};
