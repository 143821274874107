import React, { FC } from 'react';

export const AppLogo: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="58px"
    height="24px"
    viewBox="0 0 58 24"
    fill="none"
  >
    <defs>
      <clipPath id="clipPath">
        <rect fill="none" x="-2.65" y="-1.48" width="277.8" height="119.08" />
      </clipPath>
    </defs>
    <g clipPath="url(#clipPa2th)">
      <path
        fill="#fcfefe"
        d="M 2.257812 0.730469 C 2.445312 0.433594 2.773438 0.25 3.132812 0.238281 L 6.964844 0.238281 C 7.324219 0.246094 7.652344 0.429688 7.839844 0.726562 L 9.757812 3.945312 C 9.929688 4.25 9.929688 4.617188 9.757812 4.921875 L 7.839844 8.148438 C 7.652344 8.445312 7.324219 8.628906 6.96875 8.640625 L 3.132812 8.640625 C 2.777344 8.628906 2.449219 8.445312 2.257812 8.152344 L 0.339844 4.9375 C 0.171875 4.628906 0.171875 4.261719 0.339844 3.957031 Z M 2.257812 0.730469 "
      />

      <path
        fill="none"
        stroke="#fcfefe"
        strokeWidth="1.5px"
        strokeMiterlimit="10"
        d="M 50.088552 75.750156 C 50.968772 74.313776 52.509157 73.425488 54.196246 73.368789 L 72.185741 73.368789 C 73.87283 73.425488 75.413215 74.294876 76.293435 75.731257 L 85.297351 91.304642 C 86.10422 92.778822 86.10422 94.555397 85.297351 96.048477 L 76.293435 111.640762 C 75.413215 113.077142 73.87283 113.96543 72.204079 114.022129 L 54.196246 114.022129 C 52.527495 113.984329 50.968772 113.096042 50.088552 111.659661 L 41.084635 96.086276 C 40.296105 94.612096 40.296105 92.835521 41.084635 91.361341 Z M 50.088552 75.750156 "
        transform="matrix(0.213015,0,0,0.206683,0.0491403,0)"
      />

      <path
        fill="none"
        stroke="#fcfefe"
        strokeWidth="1.5px"
        strokeMiterlimit="10"
        d="M 50.088552 28.614206 C 50.968772 27.196725 52.509157 26.289538 54.196246 26.232839 L 72.185741 26.232839 C 73.87283 26.289538 75.413215 27.177826 76.293435 28.595306 L 85.297351 44.168691 C 86.10422 45.642871 86.10422 47.438346 85.297351 48.912526 L 76.293435 64.523711 C 75.413215 65.941191 73.87283 66.829479 72.204079 66.905078 L 54.196246 66.905078 C 52.527495 66.848379 50.98711 65.960091 50.088552 64.542611 L 41.121311 49.007025 C 40.351119 47.513945 40.351119 45.73737 41.121311 44.26319 Z M 50.088552 28.614206 "
        transform="matrix(0.213015,0,0,0.206683,0.0491403,0)"
      />

      <path
        fill="#fcfefe"
        d="M 27.910156 21.511719 L 24.4375 21.511719 C 24.34375 21.519531 24.25 21.488281 24.179688 21.425781 C 24.105469 21.363281 24.066406 21.277344 24.058594 21.183594 L 24.058594 5.90625 C 24.058594 5.730469 24.214844 5.621094 24.4375 5.601562 L 27.910156 5.1875 C 28.003906 5.175781 28.097656 5.203125 28.167969 5.265625 C 28.238281 5.332031 28.273438 5.421875 28.265625 5.511719 L 28.265625 21.183594 C 28.265625 21.273438 28.226562 21.363281 28.160156 21.421875 C 28.09375 21.484375 28.003906 21.515625 27.910156 21.511719 "
      />
      <path
        fill="#fcfefe"
        d="M 43.238281 8.378906 L 36.390625 8.378906 L 36.390625 12.785156 L 42.5 12.785156 C 42.59375 12.777344 42.6875 12.808594 42.757812 12.867188 C 42.824219 12.929688 42.867188 13.019531 42.863281 13.109375 L 42.863281 15.367188 C 42.867188 15.460938 42.828125 15.546875 42.757812 15.605469 C 42.691406 15.667969 42.601562 15.699219 42.507812 15.695312 L 36.390625 15.695312 L 36.390625 21.164062 C 36.386719 21.261719 36.339844 21.351562 36.265625 21.417969 C 36.191406 21.484375 36.089844 21.519531 35.988281 21.511719 L 32.582031 21.511719 C 32.371094 21.515625 32.195312 21.363281 32.179688 21.164062 L 32.179688 5.816406 C 32.195312 5.617188 32.375 5.464844 32.582031 5.46875 L 43.230469 5.46875 C 43.324219 5.460938 43.414062 5.492188 43.484375 5.550781 C 43.554688 5.613281 43.59375 5.695312 43.597656 5.789062 L 43.597656 8.0625 C 43.597656 8.152344 43.558594 8.238281 43.492188 8.300781 C 43.421875 8.363281 43.332031 8.394531 43.238281 8.386719 "
      />

      <path
        fill="#fcfefe"
        d="M 51.414062 21.75 C 49.382812 21.757812 47.367188 21.476562 45.417969 20.921875 C 45.152344 20.835938 44.949219 20.683594 45.039062 20.207031 L 45.351562 18.511719 C 45.398438 18.1875 45.6875 18.078125 46 18.164062 C 46.515625 18.273438 48.769531 18.90625 50.722656 18.90625 C 52.398438 18.90625 53.628906 18.511719 53.628906 17.058594 C 53.628906 15.472656 52.136719 15.367188 50.21875 15.0625 C 47.960938 14.714844 44.851562 14.03125 44.851562 10.265625 C 44.851562 6.339844 47.621094 5.230469 51.363281 5.230469 C 53.191406 5.242188 55.011719 5.488281 56.773438 5.96875 C 57.066406 6.054688 57.335938 6.207031 57.265625 6.597656 L 56.933594 8.375 C 56.886719 8.582031 56.753906 8.789062 56.347656 8.703125 C 54.859375 8.375 53.367188 8.050781 51.851562 8.050781 C 50.398438 8.050781 49.058594 8.3125 49.058594 9.808594 C 49.058594 11.308594 50.648438 11.460938 52.5 11.742188 C 55.074219 12.132812 57.914062 12.652344 57.914062 16.777344 C 57.914062 20.53125 54.832031 21.738281 51.40625 21.738281 "
      />
    </g>
  </svg>
);

export default AppLogo;
