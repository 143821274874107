import FileUploadIcon from '@mui/icons-material/FileUpload';
import { alpha, styled } from '@mui/material';
import React, { ChangeEvent, FC, RefObject, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import Button from 'components/atoms/Button';
import FileUploadRenderer, {
  DEFAULT_MAX_FILE_SIZE,
  defaultErrorTranslations,
  ExtendedFile,
} from 'components/molecules/FileUploadRenderer';
import { BLACK } from 'config/appColors';
import { DocumentDefinition, Nullable } from 'types';

import { SelectFileDetailsModal } from '../SelectFileDetailsModal';

export interface UploadDocDefinitionButtonProps {
  docDefinition: DocumentDefinition;
  isUploading: boolean;
  onFilesChange: (
    files: File[],
    type: DocumentDefinition,
    language: Nullable<string>,
    displayName?: string
  ) => Promise<unknown>;
  onFileRejection?: (errors: FileRejection[]) => void;
  disabled?: boolean;
  isRefetchingDocs?: boolean;
}

const StyledUploadButton = styled(Button)`
  color: ${alpha(BLACK, 0.7)};
`;

export const UploadDocDefinitionButton: FC<UploadDocDefinitionButtonProps> = (
  props
) => {
  const {
    docDefinition,
    isUploading = false,
    onFilesChange,
    onFileRejection,
    disabled,
    isRefetchingDocs,
  } = props;
  const [t] = useTranslation('auditDraft');
  const [filesSeen, setFilesSeen] = useState<Record<string, boolean>>({});
  const [isSelectFileModalOpen, setIsSelectFileModalOpen] =
    useState<boolean>(false);
  const defaultLang = 'en';
  const [fileLanguage, setFileLanguage] = useState(defaultLang);
  const [fileDisplayName, setFileDisplayName] = useState('');
  const handleLanguageChange = (language = defaultLang) =>
    setFileLanguage(language);
  const handleDisplayNameChange = (event: ChangeEvent<HTMLInputElement>) =>
    setFileDisplayName(event.target.value);

  const closeFileDetailsModal = () => {
    setIsSelectFileModalOpen(false);
    handleLanguageChange();
    setFileDisplayName('');
  };

  const handleFilesChange = (files: ExtendedFile[]) => {
    const newFiles = files.filter((file) => !(file.id in filesSeen));
    const newFilesSeen = newFiles.reduce(
      (prevFilesSeen, newFile) => {
        prevFilesSeen[newFile.id] = true;
        return prevFilesSeen;
      },
      { ...filesSeen }
    );
    setFilesSeen(newFilesSeen);

    if (onFilesChange) {
      onFilesChange(
        newFiles.map((file) => file.file),
        docDefinition,
        docDefinition.languageSensitive ? fileLanguage : null,
        fileDisplayName
      ).then(() => {
        closeFileDetailsModal();
      });
    }
  };

  const getAllowedFileTypes = (allowedFileTypes: string[]) =>
    allowedFileTypes.map((ext) => `.${ext}`).join(',');

  const handleErrors = (fileRejection: FileRejection[]) => {
    const rejections = fileRejection[0]?.errors || [];
    return rejections.map(({ code, message }) => {
      const translation = defaultErrorTranslations[code];
      return (
        t(translation, {
          supportedFormats: getAllowedFileTypes(docDefinition.allowedFileTypes),
          sizeInMB: docDefinition.maxFileSize,
          ns: 'components',
        }) || message
      );
    });
  };

  const getMaxFileSize = () =>
    docDefinition.maxFileSize
      ? docDefinition.maxFileSize * 1024 * 1024
      : DEFAULT_MAX_FILE_SIZE;

  const handleOnClick = (
    openFileBrowser: () => void,
    inputRef: RefObject<HTMLInputElement>
  ) => {
    if (inputRef.current) {
      inputRef.current.accept = getAllowedFileTypes(
        docDefinition.allowedFileTypes
      );
      // currently uploading multiple files are disabled
      inputRef.current.multiple = false;
    }
    if (docDefinition.languageSensitive || docDefinition.isNameRequired) {
      setIsSelectFileModalOpen(true);
    } else openFileBrowser();
  };

  return (
    <div data-testid={`upload-${docDefinition.id}`}>
      <FileUploadRenderer
        onFilesChange={handleFilesChange}
        onErrors={onFileRejection}
        maxFileSize={getMaxFileSize()}
        fileTypes={
          // don't remove this otherwise wrong file types error will not be thrown when user selects 'All files'
          // from native select options and selects an invalid file format
          getAllowedFileTypes(docDefinition.allowedFileTypes)
        }
        render={({
          openFileBrowser,
          inputRef,
          clearErrors,
          errors,
          openFileBrowserWithoutDropzone,
        }) => {
          const preparedErrors = handleErrors(errors);

          const isLoading = isRefetchingDocs || isUploading;

          return (
            <>
              <StyledUploadButton
                variant="text"
                color="info"
                onClick={() => handleOnClick(openFileBrowser, inputRef)}
                size="small"
                loading={isLoading}
                startIcon={!isLoading ? <FileUploadIcon /> : null}
                disabled={disabled}
              >
                {!isLoading && t('Add file')}
              </StyledUploadButton>

              {isSelectFileModalOpen && (
                <SelectFileDetailsModal
                  docDefinition={docDefinition}
                  language={fileLanguage}
                  displayName={fileDisplayName}
                  handleLanguageChange={handleLanguageChange}
                  handleDisplayNameChange={handleDisplayNameChange}
                  handleClose={() => {
                    closeFileDetailsModal();
                    clearErrors();
                  }}
                  openFileBrowser={openFileBrowserWithoutDropzone}
                  errors={preparedErrors}
                  isLoading={isUploading}
                />
              )}
            </>
          );
        }}
      />
    </div>
  );
};
