import React, { FC, useEffect } from 'react';

import { usePagination } from 'components/organisms/Table';
import { TablePagination } from 'components/organisms/Table/TablePagination';

import { AllAuditsTableBody } from './AllAuditsTableBody';
import { AllAuditsTableButtons } from './AllAuditsTableButtons';
import { AllAuditsTableHead } from './AllAuditsTableHead';
import { useFiltersAndSorting } from '../../../hooks/useFiltersAndSorting';
import { useGetAdminAuditListQuery } from '../../../state/allAuditsList/api';
import { AdminTableTemplate } from '../../templates/AdminTableTemplate';

export const AllAuditsTable: FC = () => {
  const {
    pagination,
    limit,
    offset,
    setTotalRowsAmount,
    resetPaginationState,
  } = usePagination({
    enabledQueryParams: true,
    initialRowsPerPage: 50,
  });

  const { filters, sortState, filtersReadyToFetch, resetFiltersAndSorting } =
    useFiltersAndSorting({
      resetPaginationState,
    });

  const { auditList, total, status, getAllAudits } =
    useGetAdminAuditListQuery();

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  useEffect(() => {
    if (filtersReadyToFetch) {
      getAllAudits(
        {
          filter: filters,
          limit,
          offset,
          ...(sortState
            ? { sort: `${sortState.key}:${sortState.direction}` }
            : {}),
        },
        true
      );
    }
  }, [getAllAudits, filters, sortState, limit, offset, filtersReadyToFetch]);

  return (
    <div>
      <AllAuditsTableButtons resetFiltersAndSorting={resetFiltersAndSorting} />
      <AdminTableTemplate
        thead={<AllAuditsTableHead />}
        tbody={<AllAuditsTableBody data={auditList} />}
        tfoot={<TablePagination {...pagination} outsideOfTable />}
        state={status}
      />
    </div>
  );
};
