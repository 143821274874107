import { styled } from '@mui/material';

import { DateMaskedInput } from 'components/atoms/DateMaskedInput';

import { commonInputStyles } from '../StyledTextField';

const StyledDateMaskedInput = styled(DateMaskedInput)`
  ${commonInputStyles}
  input {
    text-align: center;
  }
`;

StyledDateMaskedInput.displayName = 'StyledDateMaskedInput';

export { StyledDateMaskedInput };
