import {
  Action,
  AnyAction,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { combineReducers } from 'redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { apiSlice } from './apiSlice';
import { listenerMiddleware } from './listenerMiddleware';
import { parentReducerName as adminReportsReducerName } from '../domains/adminReports/config/constants';
import { adminReportsReducer } from '../domains/adminReports/state';
import { parentReducerName as auditAppointmentReducerName } from '../domains/auditAppointment/config/constants';
import { auditAppointmentReducer } from '../domains/auditAppointment/state';
import { parentReducerName as auditDetailsReducerName } from '../domains/auditDetails/config/constants';
import { auditDetailsReducer } from '../domains/auditDetails/state';
import { parentReducerName as auditDraftReducerName } from '../domains/auditDraft/config/constants';
import { auditDraftReducer } from '../domains/auditDraft/state';
import { parentReducerName as certificateDetailsReducerName } from '../domains/certificateDetails/config/constants';
import { certificateDetailsReducer } from '../domains/certificateDetails/state';
import { parentReducerName as certificateDraftReducerName } from '../domains/certificateDraft/config/constants';
import { certificateDraftReducer } from '../domains/certificateDraft/state';
import { parentReducerName as certificationBodyReducerName } from '../domains/certificationBody/config/constants';
import { certificationBodyReducer } from '../domains/certificationBody/state';
import { parentReducerName as supplierReducerName } from '../domains/supplier/config/constants';
import { supplierReducer } from '../domains/supplier/state';
import { parentReducerName as userReducerName } from '../domains/user/config/constants';
import { userReducer } from '../domains/user/state';
import { logout } from '../domains/user/state/auth/slice';
import { parentReducerName as widgetsReducerName } from '../domains/widgets/config/constants';
import { widgetsReducer } from '../domains/widgets/state';

const reducers = combineReducers({
  [userReducerName]: userReducer,
  [auditDraftReducerName]: auditDraftReducer,
  [supplierReducerName]: supplierReducer,
  [auditAppointmentReducerName]: auditAppointmentReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [certificationBodyReducerName]: certificationBodyReducer,
  [certificateDetailsReducerName]: certificateDetailsReducer,
  [certificateDraftReducerName]: certificateDraftReducer,
  [auditDetailsReducerName]: auditDetailsReducer,
  [widgetsReducerName]: widgetsReducer,
  [adminReportsReducerName]: adminReportsReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

// eslint-disable-next-line
const reducerProxy = (state: any, action: AnyAction) => {
  // Instead 'any' should be 'RootState', but it causes circuitous usage
  if (logout.fulfilled.match(action)) {
    // reset redux store after logout
    return reducers(undefined, action);
  }

  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, reducerProxy);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).prepend(listenerMiddleware.middleware, apiSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve type

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
