import { TableBody as MuiTableBody, TableCell, TableRow } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { generateId } from 'utils/generateId';

import { columns } from '../../../config/adminTableColumns';
import { AdminAudit } from '../../../types';

interface Props {
  data: AdminAudit[];
}

export const AllAuditsTableBody: FC<Props> = (props) => {
  const { data } = props;
  const [t] = useTranslation('adminReports');
  return (
    <MuiTableBody>
      {data.map((row) => (
        <TableRow key={row.uuid}>
          {columns.map(({ cellComponent: CellComponent, width }, index) => (
            <TableCell key={generateId(row.uuid, index)} style={{ width }}>
              <CellComponent {...row} t={t} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </MuiTableBody>
  );
};
