import { styled } from '@mui/material';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import appColors from 'config/appColors';
import { CustomLink } from 'domains/widgets/components/atoms/CustomLink';
import { Supplier } from 'types';
import isIframe from 'utils/isIframe';

const StyledLink = styled(Link)`
  color: ${appColors.METALLIC_BLUE};
  text-decoration: underline;
`;

interface CustomerLinkProps {
  supplier: Supplier;
}

export const CustomerLink: FC<CustomerLinkProps> = ({ supplier }) => {
  const { coid, name, uuid } = supplier;
  const linkText = `(COID: ${coid}) ${name}`;

  return isIframe ? (
    <CustomLink redirectPayload={uuid}>{linkText}</CustomLink>
  ) : (
    <StyledLink to="#">{linkText}</StyledLink>
  );
};
