import { yiiApiClientInstance } from './axiosInstance';
import { responseInterceptor, yiiRequestInterceptor } from './interceptors';

yiiApiClientInstance.interceptors.request.use(
  yiiRequestInterceptor.success,
  yiiRequestInterceptor.reject
);

yiiApiClientInstance.interceptors.response.use(
  responseInterceptor.success,
  responseInterceptor.reject
);

export default yiiApiClientInstance;
