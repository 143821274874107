import { alpha, styled } from '@mui/material';
import React, { FC, useEffect, useMemo, useRef } from 'react';

import { StepDescription } from 'components/atoms/StepDescription';
import { StepFormTitle } from 'components/atoms/StepFormTitle';
import { AlertList } from 'components/molecules/AlertList';
import { ActionInformationSection } from 'components/organisms/ActionInformationSection';
import { BLACK } from 'config/appColors';
import { useReasonStep } from 'hooks/draft/useReasonStep';
import { getStatus } from 'utils/getStatus';
import { getTranslatedValue } from 'utils/getTranslatedValue';
import { isAnyError } from 'utils/isAnyError';
import { isAnyLoading } from 'utils/isAnyLoading';

import { useAuditDraftDetailsQuery } from '../../../state/auditDraftDetails/api';
import { useSetAuditDraftActionCommentMutation } from '../../../state/setActionComment/api';
import { useAuditDraftSetUpdateReasonMutation } from '../../../state/setUpdateReason/api';
import { FormReasonStep } from '../../../types';
import { TemplateAuditDraftStep } from '../../templates/TemplateAuditDraftStep';

type FormReasonProps = FormReasonStep['props'] & { auditDraftId: string };

const StyledStepContent = styled('div')`
  margin: 0 10px;
`;
const StyledReasonHint = styled(StepDescription)`
  margin-top: 10px;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: 0.017rem;
  color: ${alpha(BLACK, 0.5)};
  display: block;
`;

export const FormReason: FC<FormReasonProps> = (props) => {
  const {
    actionReasonOptions,
    updateOptions,
    auditDraftId,
    backAction,
    hintUpdateReasonDescription,
    nextAction,
    title,
    labelUpdateReasonDescription,
    labelUpdateReasonSelect,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const { auditDraftDetails: draftDetails, status: auditDraftDetailsStatus } =
    useAuditDraftDetailsQuery(auditDraftId);

  const {
    updateReason,
    updateDescription,
    actionReason,
    actionComment,
    isAdmin,
    handleCommentChange,
    handleReasonChange,
    handleAdminReasonChange,
    handleAdminCommentChange,
    disableNextButton,
    actionOptions,
    updateReasonOptions,
  } = useReasonStep({ actionReasonOptions, updateOptions, draftDetails });

  const {
    error: updateReasonError = [],
    setAuditDraftUpdateReason: setUpdateReasonRequest,
    status: updateReasonStatus,
  } = useAuditDraftSetUpdateReasonMutation();
  const {
    error: setActionReasonError = [],
    setAuditDraftActionComment: setActionCommentRequest,
    status: actionCommentStatus,
  } = useSetAuditDraftActionCommentMutation();

  const handleNextClick = (goNextStep: () => void) =>
    setUpdateReasonRequest({
      auditDraftId,
      reason: {
        updateReason,
        updateDescription,
      },
    })
      .then(() =>
        isAdmin
          ? setActionCommentRequest({
              auditDraftId,
              actionComment: {
                reason: actionReason,
                comment: actionComment,
              },
            })
          : Promise.resolve()
      )
      .then(goNextStep);

  const error = isAnyError(updateReasonStatus, actionCommentStatus);
  const errorMessages = useMemo(
    () => [...updateReasonError, ...setActionReasonError],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(updateReasonError), JSON.stringify(setActionReasonError)]
  );

  const nextButtonStatus = getStatus(
    isAnyLoading(actionCommentStatus, updateReasonStatus)
  );

  useEffect(() => {
    if (error && errorMessages.length > 0 && containerRef.current) {
      containerRef.current.scrollIntoView();
    }
  }, [errorMessages, error]);

  const commentLabel = (
    <>
      {getTranslatedValue(labelUpdateReasonDescription)}
      <StyledReasonHint
        variant="caption"
        content={getTranslatedValue(hintUpdateReasonDescription)}
      />
    </>
  );

  return (
    <TemplateAuditDraftStep
      contentStatus={auditDraftDetailsStatus}
      nextButtonStatus={nextButtonStatus}
      backAction={backAction}
      nextAction={nextAction}
      disableNext={disableNextButton}
      onNextClick={handleNextClick}
    >
      <div ref={containerRef} />
      <StepFormTitle content={getTranslatedValue(title)} />
      <StyledStepContent>
        {error && <AlertList alerts={errorMessages} />}
        <ActionInformationSection
          adminReason={updateReason}
          adminComment={updateDescription}
          description={getTranslatedValue(labelUpdateReasonSelect)}
          commentLabel={commentLabel}
          handleAdminReasonChange={handleReasonChange}
          handleAdminCommentChange={handleCommentChange}
          adminReasonOptions={updateReasonOptions}
          textareaRows={6}
          hideHeading
        />

        {isAdmin && (
          <ActionInformationSection
            adminReason={actionReason}
            adminComment={actionComment}
            handleAdminReasonChange={handleAdminReasonChange}
            handleAdminCommentChange={handleAdminCommentChange}
            adminReasonOptions={actionOptions}
          />
        )}
      </StyledStepContent>
    </TemplateAuditDraftStep>
  );
};
