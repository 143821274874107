import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { alpha, css, styled } from '@mui/material';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableContainer from '@mui/material/TableContainer';
import MuiTablePagination from '@mui/material/TablePagination';
import MuiTableRow from '@mui/material/TableRow';

import { BLACK, TEXT, TUNDORA } from 'config/appColors';

import {
  SortDirectionEnum,
  StyledArrowUpwardIconProps,
  StyledMuiHeaderCellProps,
  StyledMuiTableContainerProps,
  StyledMuiTableRowProps,
} from './types';
import { ErrorState } from '../../molecules/ErrorState';

export const StyledErrorState = styled(ErrorState)`
  background: ${alpha(BLACK, 0.04)};
`;

export const StyledMuiHeaderCell = styled(
  MuiTableCell
)<StyledMuiHeaderCellProps>`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${TEXT.PRIMARY};
  cursor: ${({ $isSortable }) => ($isSortable ? 'pointer' : 'auto')};
  user-select: none;

  &&.table-checkbox {
    label {
      margin: 0;
    }
  }
`;

export const StyledMuiHeaderCellContent = styled('div')`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const StyledArrowUpwardIcon = styled(
  ArrowUpwardIcon
)<StyledArrowUpwardIconProps>`
  color: ${alpha(BLACK, 0.54)};
  transform: ${({ $direction }) =>
    $direction === SortDirectionEnum.ASC ? 'rotate(0) ' : 'rotate(180deg)'};
`;

export const StyledMuiTablePagination = styled(MuiTablePagination)`
  & .MuiTablePagination-selectLabel,
  & .MuiTablePagination-displayedRows,
  & .MuiTablePagination-actions,
  & .MuiTablePagination-select,
  & .MuiTablePagination-selectIcon {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  && .MuiTablePagination-select {
    padding-right: 15px;
    padding-top: 8px;
  }

  border: none;

  & p:nth-of-type(2) {
    color: ${alpha(BLACK, 0.7)};
    font-weight: 400;
  }
` as typeof MuiTablePagination;

export const StyledMuiCell = styled(MuiTableCell)`
  font-size: 1.4rem;
  color: ${TUNDORA};
  font-weight: 400;

  &&.table-checkbox {
    label {
      margin: 0;
    }
  }
`;

export const ActionsContainer = styled('div')`
  display: flex;
  margin-left: 20px;
`;

export const StyledMuiTableContainer = styled(
  MuiTableContainer
)<StyledMuiTableContainerProps>`
  max-height: ${({ $maxHeight }) => $maxHeight};
`;

export const StyledMuiTableRow = styled(MuiTableRow)<StyledMuiTableRowProps>`
  ${({ $isLastColumn }) =>
    $isLastColumn &&
    css`
      td {
        border-bottom: none;
      }
    `}

  cursor: ${({ $cursorPointer }) => ($cursorPointer ? 'pointer' : 'auto')};
`;
