import { styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import {
  EmptyStar,
  FilledStar,
  ShapeWithArrow,
  ShapeWithQuestionMark,
} from 'assets/icons';
import { METALLIC_BLUE, WHITE } from 'config/appColors';
import { TranslatedText } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { StatusIndicator } from './StatusIndicator';

const StyledStatusIndicator = styled(StatusIndicator)`
  display: inline-flex;
  align-items: center;
  gap: 5px;
`;

interface StatusIndicatorWithIconProps {
  iconData: {
    id: string;
    text: TranslatedText;
  };
}

const ICONS_MAP: Record<string, ReactNode> = {
  fsc: <EmptyStar />,
  'ua-star': <FilledStar />,
  'outsourced-production': <ShapeWithArrow />,
  exclusions: <ShapeWithQuestionMark />,
};

export const StatusIndicatorWithIcon: FC<StatusIndicatorWithIconProps> = (
  props
) => {
  const {
    iconData: { id, text },
  } = props;

  const icon = ICONS_MAP[id] || '';
  const label = (
    <>
      {icon} {getTranslatedValue(text)}
    </>
  );

  return (
    <StyledStatusIndicator
      label={label}
      bgColor={METALLIC_BLUE}
      textColor={WHITE}
    />
  );
};
