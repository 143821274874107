import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { QueryParams } from 'utils/queryParams';

import { SortStateType } from '../../../components/organisms/Table/types';
import { Filters, Payload, UseFiltersAndSorting } from '../types';
import { getInitialFilters } from '../utils/getInitialFilters';

interface UseFiltersAndSortingProps {
  resetPaginationState?: () => void;
}

export const useFiltersAndSorting = ({
  resetPaginationState,
}: UseFiltersAndSortingProps = {}): UseFiltersAndSorting => {
  const { replace } = useHistory();

  const { getParam, parseParams, updateQueryParams, stringifyParams } =
    QueryParams;

  const initialFilters = getInitialFilters();
  const filtersInURL = getParam('filters');
  const parsedFilters = useMemo(
    () => parseParams(filtersInURL || '') as Filters,
    [parseParams, filtersInURL]
  );

  const sortStateInURL = getParam('sort');
  const parsedSortState = useMemo(
    () =>
      sortStateInURL ? (parseParams(sortStateInURL) as SortStateType) : null,
    [parseParams, sortStateInURL]
  );

  const updateURL = useCallback(
    (value: Record<string, unknown>, mergeParams = true) =>
      replace({
        search: updateQueryParams(value, mergeParams),
      }),
    [replace, updateQueryParams]
  );

  const setFilterValue = ({
    filterKey,
    value,
  }: Payload | Payload<string[]>): void =>
    updateURL({
      filters: stringifyParams({
        ...parsedFilters,
        [filterKey]: value,
      }),
    });

  const removeValueFromFilterList = ({ filterKey, value }: Payload<string>) => {
    const filterList = parsedFilters[filterKey];

    if (Array.isArray(filterList)) {
      setFilterValue({
        filterKey,
        value: filterList.filter((filter) => filter !== value),
      });
    } else if (filterKey in parsedFilters) {
      // console.warn only for development purposes, we can get rid of it once table is finished
      console.warn(
        "You're trying to remove element of filter list by passing wrong key"
      );
    }
  };

  const setSortState = (newSortState: SortStateType) =>
    updateURL({ sort: stringifyParams(newSortState) });

  const resetFiltersAndSorting = () => {
    updateURL(
      {
        filters: stringifyParams(initialFilters),
      },
      false
    );
    resetPaginationState?.();
  };

  useEffect(() => {
    if (!filtersInURL) {
      // if param doesn't exist in URL set initial params
      updateURL({
        filters: stringifyParams(initialFilters),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersInURL]);

  return {
    filters: parsedFilters,
    setFilterValue,
    removeValueFromFilterList,
    sortState: parsedSortState,
    setSortState,
    resetFiltersAndSorting,
    filtersReadyToFetch: !!filtersInURL,
  };
};
