const appColors = {
  SUCCESS_1: '#4CAF50',
  SUCCESS_2: '#75C979',
  ERROR_1: '#D8524E',
  ERROR_2: '#DE8683',
  ERROR_3: '#E13839',
  CANDY_PINK: '#E57373',
  ALTO: '#e0e0e0', // MUI default btn
  ARGENT: '#bfbfbf',
  AQUA_HAZE: '#f4f6f9',
  ATHENS_GRAY: '#edf0f4',
  BLACK: '#000000',
  CHINESE_SILVER: '#CCCCCC',
  CYAN_BLUE: '#99ABBE',
  DODGER_BLUE: '#398bf7',
  HONOLULU_BLUE: '#006EAB',
  ELM: '#19857b',
  WARNING_OLD: '#FFAF52',
  GHOST: '#ced4da',
  GREEN_VOGUE: '#05305d',
  NEVADA: '#67757c',
  PALE_SKY: '#687384',
  PRIMARY: {
    50: '#8098af',
    100: '#66839E',
    200: '#4D6E8E',
    300: '#33597E',
    400: '#1A456E',
    500: '#00305E',
    600: '#00264B',
    700: '#001D38',
    800: '#001326',
    900: '#000A13',
  },
  PRUSSIAN_BLUE: '#002850',
  SECONDARY: {
    50: '#ebf2f8',
    100: '#c2d7e9',
    200: '#99bddb',
    300: '#85afd4',
    400: '#70a2cd',
    500: '#5c95c5',
    600: '#4787be',
    700: '#337ab7',
    800: '#2e6ea5',
    900: '#296292',
  },
  TEXT: {
    PRIMARY: '#000000b3',
    SECONDARY: '#00000080',
    DISABLED: '#00000059',
    ON_SURFACE: '#00000099',
  },
  ERROR: {
    LIGHT: '#EF5350',
  },
  WARNING: {
    LIGHT: '#FF9800',
    MAIN: '#ED6C02',
  },
  INFO: {
    LIGHT: '#03A9F4',
  },
  SILVER: '#cacaca',
  SILVER_CHALICE: '#A9A9A9',
  SILVER_SAND: '#b1b8bb',
  MOSTLY_WHITE_GRAY: '#f5f5f5',
  MOSTLY_DESATURATED_DARK_BLUE: '#6e88a2',
  STEEL_BLUE: '#4787BE',
  ANTI_FLASH_WHITE: '#f2f2f2',
  TOWER_GRAY: '#a6b7bf',
  SPANISH_GRAY: '#9a9a9a',
  CULTURED_GRAY: '#f8f8f8',
  TUNDORA: '#464646',
  TROUT: '#495057',
  WHITE: '#ffffff',
  LIGHT_GRAY: '#d5d5d5',
  LIGHT_GRAY_1: '#e9e9e9',
  COBALT_BLUE: '#0056AC',
  METALLIC_BLUE: '#335373',
  CRYSTAL_BELL: '#EFEFEF',
};

export default appColors;

export const {
  SUCCESS_1,
  SUCCESS_2,
  ERROR_1,
  ERROR_2,
  ERROR_3,
  CANDY_PINK,
  ALTO,
  AQUA_HAZE,
  ARGENT,
  ATHENS_GRAY,
  BLACK,
  CHINESE_SILVER,
  CYAN_BLUE,
  DODGER_BLUE,
  HONOLULU_BLUE,
  ELM,
  GHOST,
  GREEN_VOGUE,
  NEVADA,
  PALE_SKY,
  PRIMARY,
  PRUSSIAN_BLUE,
  SECONDARY,
  SILVER,
  SILVER_CHALICE,
  SILVER_SAND,
  STEEL_BLUE,
  ANTI_FLASH_WHITE,
  TOWER_GRAY,
  TUNDORA,
  TROUT,
  MOSTLY_WHITE_GRAY,
  MOSTLY_DESATURATED_DARK_BLUE,
  WHITE,
  CULTURED_GRAY,
  SPANISH_GRAY,
  WARNING_OLD,
  LIGHT_GRAY,
  LIGHT_GRAY_1,
  TEXT,
  ERROR,
  WARNING,
  INFO,
  COBALT_BLUE,
  METALLIC_BLUE,
  CRYSTAL_BELL,
} = appColors;
