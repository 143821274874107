import { styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { LoaderIcon } from 'components/atoms/LoaderIcon';
import { LoaderStatusEnum, UserRoleEnum } from 'types';
import { isDev } from 'utils/environments';

import routes from './config/routes';
import {
  logout,
  refreshAccessToken,
  selectAuthLoading,
  selectIsLogged,
  selectUserDetails,
} from './domains/user/state/auth/slice';
import {
  WidgetErrorPayload,
  WidgetErrorTypeEnum,
} from './domains/widgets/components/organisms/WidgetErrorScreen/types';
import { trigger } from './utils/customEvents';
import { REDIRECT_TO_IFRAME_ERROR_EVENT } from './utils/customEvents/eventNames';
import { getReactEnvVar } from './utils/getReactEnvVar';
import isIframe from './utils/isIframe';

const StyledLoaderIcon = styled(LoaderIcon)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface AuthRouteProps extends RouteProps {
  roles?: UserRoleEnum[];
}

const AuthRoute: FC<AuthRouteProps> = (props: AuthRouteProps) => {
  const isLogged = useSelector(selectIsLogged);
  const userDetails = useSelector(selectUserDetails);
  const status = useSelector(selectAuthLoading);
  const isIdle = status === LoaderStatusEnum.IDLE;
  const isLoading = status === LoaderStatusEnum.LOADING;
  const dispatch = useDispatch();
  const { location, roles = [] } = props;

  useIdleTimer({
    timeout: 1000 * parseInt(getReactEnvVar('AUTO_LOGOUT_COUNTER') || '30', 10),
    onIdle: () => {
      if (isIframe) {
        return trigger<WidgetErrorPayload>(REDIRECT_TO_IFRAME_ERROR_EVENT, {
          type: WidgetErrorTypeEnum.SESSION_EXPIRED,
        });
      }

      return dispatch(logout());
    },
    debounce: 500,
  });

  useEffect(() => {
    // TODO: once system has setting cookie after login to yii the if statement is unnecessary
    // iframe has its own logic for auth yet
    if (isIframe) return;

    if (!isLogged) {
      dispatch(refreshAccessToken());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  if (isLoading || (!isLogged && isIdle)) {
    return <StyledLoaderIcon />;
  }

  if (!isLogged && !isIframe) {
    if (!isDev) {
      window.open(
        getReactEnvVar('YII_LOGIN_PAGE'),
        '_blank',
        'noopener,noreferrer'
      );
    }

    return (
      <Redirect
        to={{
          pathname: isDev ? routes.LOGIN : routes.PERMISSION_DENIED,
          state: {
            from: `${location?.pathname}${location?.search}${location?.hash}`,
          },
        }}
      />
    );
  }

  if (
    roles.length === 0 ||
    roles.some((role) => userDetails?.currentContext?.roles.includes(role))
  ) {
    return <Route {...props} />;
  }

  return (
    <Redirect
      to={{
        pathname: routes.PERMISSION_DENIED,
      }}
    />
  );
};

export default AuthRoute;
