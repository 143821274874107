import { alpha, styled } from '@mui/material';
import React, { FC } from 'react';

import { BLACK, ERROR } from 'config/appColors';
import { TranslatedText } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

interface DynamicFormItemLabelProps {
  text: TranslatedText;
  error?: string;
  className?: string;
  hideColon?: boolean;
}

const StyledLabel = styled('label')`
  display: block;
  padding: 0 0 12px;
  font-weight: 400;
  color: ${alpha(BLACK, 0.7)};
  line-height: 2.4rem;
  letter-spacing: 0.015rem;
`;
const StyledError = styled('span')`
  margin-left: 4px;
  font-size: 1.4rem;
  color: ${ERROR.LIGHT};
`;

export const DynamicFormItemLabel: FC<DynamicFormItemLabelProps> = (props) => {
  const { text, error, className, hideColon = false } = props;
  const translatedText = getTranslatedValue(text);

  return (
    <StyledLabel className={className}>
      {translatedText}
      {!hideColon && ':'}
      <StyledError>{error}</StyledError>
    </StyledLabel>
  );
};
