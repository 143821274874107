import { styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { METALLIC_BLUE } from 'config/appColors';
import { PostMessage } from 'utils/postMessage';

const StyledLink = styled('span')`
  color: ${METALLIC_BLUE};
  text-decoration: underline;
  cursor: pointer;
`;

interface CustomLinkProps {
  children: ReactNode;
  redirectPayload?: string;
}

export const CustomLink: FC<CustomLinkProps> = ({
  children,
  redirectPayload = '',
}) => (
  <StyledLink
    onClick={(e) => {
      e.stopPropagation();
      PostMessage.redirect(redirectPayload);
    }}
  >
    {children}
  </StyledLink>
);
