import { alpha, styled } from '@mui/material';
import React, { FC } from 'react';

import { Heading } from 'components/atoms/Heading';
import { BLACK } from 'config/appColors';
import { TranslatedText } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

const StyledHeading = styled(Heading)`
  color: ${alpha(BLACK, 0.7)};
  font-size: 2rem;
  line-height: 3.2rem;
  letter-spacing: 0.015rem;
  font-weight: 500;
  margin-bottom: 15px;
`;

interface TabSectionHeadingProps {
  text: TranslatedText | string;
  className?: string;
}

export const TabSectionHeading: FC<TabSectionHeadingProps> = (props) => {
  const { text, className } = props;

  return (
    <StyledHeading variant="h3" className={className}>
      {typeof text === 'string' ? text : getTranslatedValue(text)}
    </StyledHeading>
  );
};
