import { alpha, styled } from '@mui/material';
import React, { FC } from 'react';

import { Logger } from 'components/atoms/Logger';
import { BLACK } from 'config/appColors';
import { RoleMapper } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

const StyledRole = styled('p')`
  letter-spacing: 0.017rem;
  color: ${alpha(BLACK, 0.7)};
  margin: 0 0 3px;
`;

const StyledDescription = styled('p')`
  margin: 0;
  font-size: 1.2rem;
  color: ${alpha(BLACK, 0.5)};
  letter-spacing: 0.04rem;
`;

interface AuditParticipantRoleProps {
  roleMapper: RoleMapper[];
  role: string;
  hideDescription?: boolean;
}

export const AuditParticipantRole: FC<AuditParticipantRoleProps> = (props) => {
  const { roleMapper, role, hideDescription = false } = props;
  const roleToDisplay = roleMapper.find(
    (roleConfig) => roleConfig.role === role
  );

  if (!roleToDisplay) {
    return (
      <Logger
        message={`There is no such as ${role} in roleMapper in template`}
      />
    );
  }

  const { name, description } = roleToDisplay;

  return (
    <>
      <StyledRole>{getTranslatedValue(name)}</StyledRole>
      {description && !hideDescription && (
        <StyledDescription>{getTranslatedValue(description)}</StyledDescription>
      )}
    </>
  );
};
