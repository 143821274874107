import CloseIcon from '@mui/icons-material/Close';
import { alpha, styled } from '@mui/material';

import Button from 'components/atoms/Button';
import { BLACK, WHITE } from 'config/appColors';

import {
  StyledDescriptionProps,
  StyledIconWrapperProps,
  StyledWrapperProps,
} from './types';

export const StyledWrapper = styled('div')<StyledWrapperProps>`
  position: relative;
  width: 100%;
  padding: 25px;
  margin-bottom: 20px;
  border: ${(props) => `2px solid ${props.$borderColor}`};
  background: ${WHITE};
`;

export const StyledTitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const StyledTitle = styled('span')`
  font-size: 2rem;
  font-weight: 500;
  line-height: 3.2rem;
  letter-spacing: 0.015rem;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
`;

export const StyledDescription = styled('p')<StyledDescriptionProps>`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.017rem;
  width: 97%;
  margin: 0;
  color: ${(props) => props.$color};
`;

export const StyledIconWrapper = styled('div')<StyledIconWrapperProps>`
  > * {
    font-size: 2.4rem;
  }
  display: flex;
  color: ${(props) => props.$color};
  margin-right: 10px;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 2.4rem;
  color: ${alpha(BLACK, 0.54)};
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  font-size: 1.3rem;
  line-height: 2.2rem;
  letter-spacing: 0.046rem;
  color: ${alpha(BLACK, 0.7)};
  padding: 4px 10px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
    0 1px 5px rgba(0, 0, 0, 0.12);
`;
