import { FC, ReactNode } from 'react';
import { TFunction } from 'react-i18next';

import { SortStateType } from 'components/organisms/Table/types';
import { LoaderStatusEnum, Nullable, Option } from 'types';

import { AdminAudit } from './domain';

export enum FilterTypeEnum {
  TEXT = 'text',
  CHECKBOX = 'checkbox',
  MULTISELECT = 'multiselect',
  DATE = 'date',
  DATE_RANGE = 'date-range',
}
export interface CheckboxFilter {
  type: FilterTypeEnum.CHECKBOX;
  defaultValue: Nullable<boolean>;
  label?: string;
  filterKey: string;
}

export interface TextFieldFilter {
  type: FilterTypeEnum.TEXT;
  defaultValue?: string;
  filterKey: string;
  minLength?: number;
}

export interface MultiSelectFilter {
  type: FilterTypeEnum.MULTISELECT;
  defaultValue?: string[];
  filterKey: string;
  options: Option[];
}

export interface DateFilter {
  type: FilterTypeEnum.DATE;
  defaultValue?: string;
  filterKey: string;
}

export interface DateRangeFilter {
  type: FilterTypeEnum.DATE_RANGE;
  startDefaultValue?: string;
  endDefaultValue?: string;
  startFilterKey: string;
  endFilterKey: string;
}

export type TableFilter =
  | CheckboxFilter
  | TextFieldFilter
  | MultiSelectFilter
  | DateFilter
  | DateRangeFilter;

export interface AdminColumn {
  header: string; // header is also used for showing/hiding hidable columns
  filtersConfig?: TableFilter[];
  filterComponent?: (filters: UseFiltersAndSorting) => ReactNode;
  cellComponent: AdminTableCell;
  sortKey?: string;
  onSortClick?: (sortState: Nullable<SortStateType>) => void;
  width?: `${number}px`;
  resizable?: boolean;
  hidable?: boolean;
}

export enum LoaderStatusWithEmptyEnum {
  EMPTY = 'empty',
}

export type ExtendedLoaderStatusEnum =
  | LoaderStatusEnum
  | LoaderStatusWithEmptyEnum;

export type AdminTableCell<
  T extends Record<string, unknown> = Record<string, unknown>
> = FC<
  AdminAudit & {
    t: TFunction<'adminReports', undefined>;
    className?: string;
  } & T
>;

export type PrimitiveFilter = Nullable<boolean> | string;

type FilterType = PrimitiveFilter | string[];

export type Filters = Record<string, FilterType>;

export type Payload<T = PrimitiveFilter> = { filterKey: string; value: T };

export interface UseFiltersAndSorting {
  filters: Filters;
  removeValueFromFilterList: (payload: Payload<string>) => void;
  setFilterValue: (payload: Payload | Payload<string[]>) => void;
  sortState: Nullable<SortStateType>;
  setSortState: (newSortState: SortStateType) => void;
  resetFiltersAndSorting: () => void;
  filtersReadyToFetch: boolean;
}
