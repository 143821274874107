import { alpha, Paper, styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ErrorState } from 'components/molecules/ErrorState';
import Loader from 'components/molecules/Loader';
import { PageHeader } from 'components/molecules/PageHeader';
import TemplatePageDefault from 'components/templates/TemplatePageDefault';
import { BLACK, TEXT, WHITE } from 'config/appColors';
import { UNEXPECTED_ERROR_TRY_AGAIN } from 'config/constants';
import { ConditionalCertificateModals } from 'domains/certificateDetails/components/organisms/ConditionalCertificateModals';
import { MappedCertificateTabs } from 'domains/certificateDetails/components/organisms/MappedCertificateTabs';
import { useGetCertificateDetailsQuery } from 'domains/certificateDetails/state/certificateDetails/api';
import { useGetCertificateDetailsTemplateQuery } from 'domains/certificateDetails/state/certificateDetailsTemplate/api';
import {
  getSupplierDetails,
  selectSupplierDetails,
} from 'domains/supplier/state/details/slice';
import { LoaderStatusEnum } from 'types';
import { getSupplierDetailsTitle } from 'utils/getSupplierDetailsTitle';
import { getTemplatePageHeader } from 'utils/getTemplatePageHeader';

const StyledPageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledPageHeaderWrapper = styled('div')<{ $isLoading: boolean }>`
  height: 80px;
  border-bottom: 1px solid ${alpha(BLACK, 0.25)};
  visibility: ${({ $isLoading }) => ($isLoading ? 'hidden' : 'visible')};
  background: ${WHITE};
`;

const StyledPageHeader = styled(PageHeader)`
  h2 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 400;
    color: ${TEXT.PRIMARY};

    span {
      color: ${TEXT.SECONDARY};
    }
  }
`;

const StyledTabWrapper = styled(Paper)`
  width: 750px;
  margin: 40px auto 0 auto;

  @media screen and (min-width: 1000px) {
    width: 960px;
  }
`;

export const WidgetCertificateDetails: FC = () => {
  const [t] = useTranslation('certificateDetails');
  const dispatch = useDispatch();

  const { supplierId, certificateUuid } =
    useParams<{ supplierId: string; certificateUuid: string }>();
  const selectedSupplier = useSelector(selectSupplierDetails);
  const supplierDetails = getSupplierDetailsTitle(selectedSupplier, true);

  useEffect(() => {
    if (!selectedSupplier) {
      dispatch(getSupplierDetails({ supplierId }));
    }
  }, [selectedSupplier, supplierId, dispatch]);

  const {
    isLoading: isCertificateDetailsLoading,
    isError: isCertificateDetailsError,
    isSuccess: isCertificateDetailsSuccess,
  } = useGetCertificateDetailsQuery({
    certificateUuid,
  });

  const {
    certificateDetailsTemplate,
    isError: isCertificateDetailsTemplateError,
    isLoading: isCertificateDetailsTemplateLoading,
  } = useGetCertificateDetailsTemplateQuery(
    certificateUuid,
    !isCertificateDetailsSuccess
  );
  const pageTitle = getTemplatePageHeader(certificateDetailsTemplate?.template);

  const loaderStatus =
    isCertificateDetailsLoading || isCertificateDetailsTemplateLoading
      ? LoaderStatusEnum.LOADING
      : LoaderStatusEnum.SUCCESS;

  if (isCertificateDetailsError || isCertificateDetailsTemplateError) {
    return (
      <TemplatePageDefault hidePageHeader hideAside hideHeader hideSupport>
        <ErrorState
          heading={t('Failed to load Data.', { ns: 'components' })}
          description={t(UNEXPECTED_ERROR_TRY_AGAIN, { ns: 'common' })}
        />
      </TemplatePageDefault>
    );
  }

  return (
    <>
      <StyledPageWrapper>
        <StyledPageHeaderWrapper
          $isLoading={loaderStatus === LoaderStatusEnum.LOADING}
        >
          <StyledPageHeader
            title={pageTitle}
            details={supplierDetails}
            withBackButton
            hideBreadcrumbs
          />
        </StyledPageHeaderWrapper>
        <Loader status={loaderStatus} minHeight="200px">
          <StyledTabWrapper>
            <MappedCertificateTabs />
          </StyledTabWrapper>
        </Loader>
      </StyledPageWrapper>
      <ConditionalCertificateModals />
    </>
  );
};
