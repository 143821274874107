import { styled } from '@mui/material';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/system';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Loader from 'components/molecules/Loader';
import {
  PageHeader,
  useShowPageHeaderBackButton,
} from 'components/molecules/PageHeader';
import { Tabs } from 'components/organisms/Tabs';
import TemplatePageDefault from 'components/templates/TemplatePageDefault';
import { BLACK, TEXT, WHITE } from 'config/appColors';

import { AppointmentDetailsActionsDropdown } from '../../components/molecules/AppointmentDetailsActionsDropdown';
import { ConditionalModals } from '../../components/organisms/ConditionalModals';
import { DetailsDetailsTab } from '../../components/organisms/DetailsDetailsTab';
import { DetailsLogsTab } from '../../components/organisms/DetailsLogsTab';
import { AuditAppointmentActionsEnum } from '../../config/actions';
import { useIsPermittedTo } from '../../hooks/useIsPermittedTo';
import {
  getAuditAppointment,
  resetAuditAppointmentDetailsState,
  selectFinalAuditDetailAndActionsLoading,
} from '../../state/details/slice';

const StyledPaper = styled(Paper)`
  width: 960px;
  margin: 40px auto 0 auto;
`;

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledPageHeaderWrapper = styled('div')`
  background: ${WHITE};
  border-bottom: 1px solid ${alpha(BLACK, 0.25)};
`;

const StyledPageHeader = styled(PageHeader)`
  h2 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 400;
    color: ${TEXT.PRIMARY};

    span {
      color: ${TEXT.SECONDARY};
    }
  }
`;

export const PageAuditAppointmentDetails: FC = () => {
  const { auditAppointmentId } = useParams<{ auditAppointmentId: string }>();
  const [t] = useTranslation('auditAppointment');
  const dispatch = useDispatch();
  const showBackButton = useShowPageHeaderBackButton();

  const { isPermittedTo } = useIsPermittedTo();

  const finalStatus = useSelector(selectFinalAuditDetailAndActionsLoading);

  useEffect(() => {
    dispatch(getAuditAppointment(auditAppointmentId));

    return () => {
      dispatch(resetAuditAppointmentDetailsState());
    };
  }, [auditAppointmentId, dispatch]);

  return (
    <TemplatePageDefault hidePageHeader>
      <StyledContainer>
        <StyledPageHeaderWrapper>
          <StyledPageHeader
            title={t('Audit Plan')}
            hideBreadcrumbs
            withBackButton
          />
        </StyledPageHeaderWrapper>
        <Loader status={finalStatus} minHeight="150px">
          <StyledPaper>
            <Tabs
              items={[
                {
                  label: t('Details'),
                  content: (
                    <DetailsDetailsTab appointmentId={auditAppointmentId} />
                  ),
                },
                ...(isPermittedTo(AuditAppointmentActionsEnum.LOGS)
                  ? [
                      {
                        label: t('Logs'),
                        content: (
                          <DetailsLogsTab appointmentId={auditAppointmentId} />
                        ),
                      },
                    ]
                  : []),
              ]}
              routeParamName="tab"
              routeState={{ showBackButton }}
              tabNavChildComponent={<AppointmentDetailsActionsDropdown />}
            />
          </StyledPaper>
        </Loader>
      </StyledContainer>
      <ConditionalModals appointmentId={auditAppointmentId} />
    </TemplatePageDefault>
  );
};

export default PageAuditAppointmentDetails;
