import LockIcon from '@mui/icons-material/Lock';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { alpha, styled } from '@mui/material';
import React, { FC } from 'react';

import { BLACK, ERROR_1 } from 'config/appColors';
import { useGetAuditDetailsQuery } from 'domains/auditDetails/state/auditDetails/api';
import { AuditDetailsTabEnum } from 'domains/auditDetails/types';
import { isReinstate } from 'utils/isReinstate';

interface StyledTabItemLabelProps {
  $color: string;
}

const StyledTabItemLabel = styled('span')<StyledTabItemLabelProps>`
  display: flex;
  justify-content: space-between;
  gap: 7px;

  svg {
    color: ${({ $color }) => $color};
  }
`;

interface TabItemLabelProps {
  auditUuid: string;
  label: string;
  tabId: AuditDetailsTabEnum;
}

export const TabItemLabel: FC<TabItemLabelProps> = ({
  auditUuid,
  label,
  tabId,
}) => {
  const { auditDetails } = useGetAuditDetailsQuery({ auditUuid });

  const certificate = auditDetails?.certificate;
  const latestLock = certificate?.latestLock;
  const isUnlocked = isReinstate(latestLock);

  if (
    tabId === AuditDetailsTabEnum.CERTIFICATE &&
    (certificate?.activeLock || isUnlocked)
  ) {
    const [Icon, color] = isUnlocked
      ? [LockOpenOutlinedIcon, alpha(BLACK, 0.3)]
      : [LockIcon, ERROR_1];

    return (
      <StyledTabItemLabel $color={color}>
        <Icon fontSize="large" /> {label}
      </StyledTabItemLabel>
    );
  }

  return <>{label}</>;
};
