import { alpha, styled } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

import { StepDescription } from 'components/atoms/StepDescription';
import { StepFormTitle } from 'components/atoms/StepFormTitle';
import { AlertList } from 'components/molecules/AlertList';
import { RadioGroup } from 'components/molecules/RadioGroup';
import { BLACK } from 'config/appColors';
import { LoaderStatusEnum } from 'types';
import { renderDateRange } from 'utils/dates';
import { getStatus } from 'utils/getStatus';
import { getTranslatedValue } from 'utils/getTranslatedValue';
import { isAnyLoading } from 'utils/isAnyLoading';

import { useGetAuditAppointmentOptionsQuery } from '../../../state/auditAppointmentOptions/api';
import { useAuditDraftDetailsQuery } from '../../../state/auditDraftDetails/api';
import { useResetAuditDraftAppointmentMutation } from '../../../state/resetAppointment/api';
import { useSetAuditDraftAppointmentMutation } from '../../../state/setAppointment/api';
import { FormAppointmentSelectionStep } from '../../../types';
import { TemplateAuditDraftStep } from '../../templates/TemplateAuditDraftStep';

type FormAppointmentSelectionProps = FormAppointmentSelectionStep['props'] & {
  auditDraftId: string;
};

const StyledStepContent = styled('div')`
  margin: 0 10px;
`;
const StyledNoDataText = styled('div')`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  letter-spacing: 0.015rem;
  color: ${alpha(BLACK, 0.7)};
  border-radius: 5px;
  padding: 10px;
  background: ${alpha(BLACK, 0.04)};
`;
const StyledStepDescription = styled(StepDescription)`
  margin-bottom: 20px;
`;
const StyledRadioGroup = styled(RadioGroup)`
  margin-left: 12px;
`;

export const FormAppointmentSelection: FC<FormAppointmentSelectionProps> = (
  props
) => {
  const {
    auditDraftId,
    title,
    instructionText,
    noDataText,
    backAction,
    nextAction,
  } = props;
  const [selectedAppointmentId, setSelectedAppointmentId] = useState('');
  const [resetRequired, setResetRequired] = useState(false);
  const { auditDraftDetails } = useAuditDraftDetailsQuery(auditDraftId);
  const {
    status: contentStatus,
    appointments,
    isLoading: isContentLoading,
    isMandatory,
  } = useGetAuditAppointmentOptionsQuery({
    auditDraftId,
  });
  const options = appointments.map(
    ({ uuid, module: { name }, kind, dueDate: { start, end } }) => ({
      id: uuid,
      name: `${name}, ${kind}, ${renderDateRange(start, end)} `,
      enabled: true,
    })
  );
  const {
    setAppointment,
    status: addRequestStatus,
    error: addRequestError = [],
  } = useSetAuditDraftAppointmentMutation();
  const {
    resetAppointment,
    status: resetRequestStatus,
    error: resetRequestError = [],
  } = useResetAuditDraftAppointmentMutation();
  const isLoading = isAnyLoading(addRequestStatus, resetRequestStatus);
  const handleNextClick = async (goNextStep: () => void) => {
    if (selectedAppointmentId) {
      await setAppointment({
        auditDraftId,
        appointmentId: selectedAppointmentId,
      });
    } else if (resetRequired) {
      await resetAppointment({ auditDraftId });
    }
    goNextStep();
  };
  const disableNextButton =
    (isMandatory && !selectedAppointmentId) || isContentLoading;
  const appointmentIdFromDraft = auditDraftDetails?.appointmentUuid;
  const handleRadioClick = (value: string) => {
    if (value === selectedAppointmentId && !isMandatory) {
      // for non-mandatory is possible to reset
      if (appointmentIdFromDraft) setResetRequired(true);
      setSelectedAppointmentId('');
    } else {
      setResetRequired(false);
      setSelectedAppointmentId(value);
    }
  };
  const finalError = [...addRequestError, ...resetRequestError];
  const showError = finalError.length > 0;

  useEffect(() => {
    // select option if already exists on BE
    if (
      !selectedAppointmentId &&
      appointmentIdFromDraft &&
      contentStatus === LoaderStatusEnum.SUCCESS &&
      !resetRequired
    ) {
      const selectedOption = options.find(
        ({ id }) => id === appointmentIdFromDraft
      );

      if (selectedOption) {
        setSelectedAppointmentId(selectedOption.id);
      } else {
        setResetRequired(true);
      }
    }
  }, [
    selectedAppointmentId,
    options,
    appointmentIdFromDraft,
    contentStatus,
    resetRequired,
  ]);

  return (
    <TemplateAuditDraftStep
      contentStatus={contentStatus}
      nextButtonStatus={getStatus(isLoading)}
      backAction={backAction}
      nextAction={nextAction}
      disableNext={disableNextButton}
      onNextClick={handleNextClick}
    >
      <StepFormTitle content={getTranslatedValue(title)} />
      <StyledStepContent>
        {showError && <AlertList alerts={finalError} />}
        <StyledStepDescription content={getTranslatedValue(instructionText)} />
        {options.length > 0 ? (
          <StyledRadioGroup
            value={selectedAppointmentId}
            options={options}
            onSingleInputClick={handleRadioClick}
          />
        ) : (
          <StyledNoDataText>{getTranslatedValue(noDataText)}</StyledNoDataText>
        )}
      </StyledStepContent>
    </TemplateAuditDraftStep>
  );
};
