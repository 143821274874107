import { alpha } from '@mui/material';
import styled from 'styled-components';

import { BLACK } from 'config/appColors';

const StyledLabelValueListItem = styled('p')`
  margin: 0;
  color: ${alpha(BLACK, 0.7)};

  &.withDivider + &.withDivider {
    border-top: 1px solid ${alpha(BLACK, 0.25)};
    padding-top: 5px;
    margin-top: 2px;
  }

  span {
    font-weight: 500;
  }
`;

StyledLabelValueListItem.displayName = 'StyledLabelValueListItem';

export { StyledLabelValueListItem };
