import { styled } from '@mui/material';
import { isAfter, isBefore } from 'date-fns';
import { debounce } from 'lodash';
import React, { FC, MutableRefObject, useEffect, useRef } from 'react';

import { Nullable } from 'types';
import { PLAIN_DATE_REGEX_WITH_DOTS } from 'utils/regex/date';

import { DateRangeFilter, UseFiltersAndSorting } from '../../../types';
import { StyledDateMaskedInput } from '../../atoms/StyledMaskedDateInput';

const StyledContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const WAIT_TIME = 500;

type DateRangeFilterCellProps = DateRangeFilter & UseFiltersAndSorting;

export const DateRangeFilterCell: FC<DateRangeFilterCellProps> = (props) => {
  const startDateInputRef = useRef<Nullable<HTMLInputElement>>(null);
  const endDateInputRef = useRef<Nullable<HTMLInputElement>>(null);
  const { startFilterKey, endFilterKey, filters, setFilterValue } = props;

  const getDate = (date: string) => {
    const [day, month, year] = date.split('.');
    return new Date(`${year}-${month}-${day}`);
  };

  // TODO: once we introduce TS 5.5.X (PL4FE-449) we can change this code to
  // const getDateValue = (key:string)=> typeof filters[key] === 'string' ? filters[key] : ''
  const getDateValue = (key: string) => {
    const val = filters[key];
    return typeof val === 'string' ? val : '';
  };

  const startDate = getDateValue(startFilterKey);
  const endDate = getDateValue(endFilterKey);

  const handleDateChange =
    (
      dateValidator: typeof isBefore,
      filterKey: string,
      ref: MutableRefObject<Nullable<HTMLInputElement>>,
      dateToCompare: string
    ) =>
    (date: string) => {
      if (date !== '' && !PLAIN_DATE_REGEX_WITH_DOTS.test(date)) return;

      const skipValidation = dateToCompare === '' || date === '';
      const isNewDateValid =
        skipValidation || dateValidator(getDate(date), getDate(dateToCompare));
      const value = isNewDateValid ? date : '';

      setFilterValue({ filterKey, value });

      if (ref?.current?.value) {
        ref.current.value = value;
      }
    };

  const handleStartDateChange = handleDateChange(
    isBefore,
    startFilterKey,
    startDateInputRef,
    endDate
  );
  const debouncedHandleStartDateChange = debounce(
    handleStartDateChange,
    WAIT_TIME
  );

  const handleEndDateChange = handleDateChange(
    isAfter,
    endFilterKey,
    endDateInputRef,
    startDate
  );
  const debouncedHandleEndDateChange = debounce(handleEndDateChange, WAIT_TIME);

  useEffect(() => {
    if (startDate === '' && startDateInputRef.current?.value) {
      startDateInputRef.current.value = '';
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate === '' && endDateInputRef.current?.value) {
      endDateInputRef.current.value = '';
    }
  }, [endDate]);

  return (
    <StyledContainer>
      <StyledDateMaskedInput
        inputRef={startDateInputRef}
        defaultValue={startDate}
        onChange={debouncedHandleStartDateChange}
      />
      -
      <StyledDateMaskedInput
        inputRef={endDateInputRef}
        defaultValue={endDate}
        onChange={debouncedHandleEndDateChange}
      />
    </StyledContainer>
  );
};
