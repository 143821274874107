import { apiClient } from './axiosInstance';
import { requestInterceptor, responseInterceptor } from './interceptors';


apiClient.interceptors.request.use(
  requestInterceptor.success,
  requestInterceptor.reject
);

apiClient.interceptors.response.use(
  responseInterceptor.success,
  responseInterceptor.reject
);

export default apiClient;
