import { alpha, styled } from '@mui/material';
import React, { FC } from 'react';

import { BLACK, METALLIC_BLUE } from 'config/appColors';
import { InfoBox } from 'types';
import { formatDate } from 'utils/dates';
import { emitTabClick } from 'utils/emitTabClick';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { useCertificateInfoBoxData } from '../../../hooks/useCertificateInfoBoxData';
import { useGetCertificateDetailsTemplateQuery } from '../../../state/certificateDetailsTemplate/api';
import {
  CertificateActionsEnum,
  CertificateDetails,
  isLockHistoryTab,
} from '../../../types';
import { InfoBoxBannerVariantEnum } from '../../molecules/CertificateDetailsInfoBox/types';

interface InfoBoxDescriptionProps {
  data: InfoBox;
  certificateDetails: CertificateDetails;
  certificateDetailsActions: CertificateActionsEnum[];
  type: InfoBoxBannerVariantEnum;
}

const StyledLink = styled('span')`
  color: ${METALLIC_BLUE};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

const StyledDescription = styled('span')`
  color: ${alpha(BLACK, 0.7)};
  display: block;
  margin-bottom: 10px;
`;

export const InfoBoxDescription: FC<InfoBoxDescriptionProps> = ({
  data,
  certificateDetails,
  certificateDetailsActions,
  type,
}) => {
  const { props } = data;
  const { activeLock, latestLock } =
    useCertificateInfoBoxData(certificateDetails);

  const { certificateDetailsTemplate } = useGetCertificateDetailsTemplateQuery(
    certificateDetails?.uuid
  );
  const lockHistoryTab =
    certificateDetailsTemplate?.template?.children.find(isLockHistoryTab);
  const lockHistoryTabName = lockHistoryTab?.props?.name;

  const linkLabel = certificateDetailsActions.includes(
    CertificateActionsEnum.CERTIFICATE_LOCK_HISTORY_VIEW
  )
    ? getTranslatedValue(props.lockHistoryLinkText)
    : undefined;

  const descriptionData = {
    [InfoBoxBannerVariantEnum.LOCK]: {
      date: activeLock?.lockDate,
      dateText: props?.lockDateText,
    },
    [InfoBoxBannerVariantEnum.UNLOCK]: {
      date: latestLock?.unlockDate,
      dateText: props?.unlockDateText,
    },
  };

  const getDescription = (): string => {
    const { date, dateText } = descriptionData[type];
    let res = getTranslatedValue(props.messageText);

    if (dateText) res += ` ${getTranslatedValue(dateText)}`;
    if (date) res += ` ${formatDate(date)}`;

    return res;
  };

  return (
    <>
      <StyledDescription>{getDescription()}</StyledDescription>
      {!props.noLink && lockHistoryTabName && (
        <StyledLink
          onClick={() => emitTabClick(getTranslatedValue(lockHistoryTabName))}
        >
          {linkLabel}
        </StyledLink>
      )}
    </>
  );
};
