import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { alpha, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/atoms/Button';
import { BLACK, SPANISH_GRAY } from 'config/appColors';

import { ALL_AUDITS_HEADER_HEIGHT } from '../../../config/constants';

interface Props {
  resetFiltersAndSorting: () => void;
}

const StyledTableButtons = styled('div')`
  height: ${ALL_AUDITS_HEADER_HEIGHT}px;
  margin: 0 4.8rem;
  display: flex;
  align-items: center;
`;

const StyledHeading = styled(Typography)`
  font-size: 2.4rem;
  line-height: 2rem;
  letter-spacing: 0.018rem;
  font-weight: 400;
  color: ${SPANISH_GRAY};
`;

const StyledButtonsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-left: auto;
`;

const StyledColumnsIcon = styled(ViewColumnIcon)`
  font-size: 3.2rem;
  margin-left: 22px;
  color: ${alpha(BLACK, 0.3)};
`;

export const AllAuditsTableButtons: FC<Props> = (props) => {
  const [t] = useTranslation('adminReports');
  const { resetFiltersAndSorting } = props;

  return (
    <StyledTableButtons>
      <StyledHeading variant="h3">
        {t('All Audits', { ns: 'components' })}
      </StyledHeading>
      <StyledButtonsContainer>
        <Button
          variant="contained"
          color="info"
          size="small"
          onClick={resetFiltersAndSorting}
        >
          {t('Reset filter')}
        </Button>
        <Button variant="contained" color="info" size="small" disabled>
          {t('export', { ns: 'components' })}
        </Button>
        <StyledColumnsIcon />
      </StyledButtonsContainer>
    </StyledTableButtons>
  );
};
