import {
  alpha,
  Autocomplete,
  AutocompleteRenderInputParams,
  Chip,
  styled,
} from '@mui/material';
import React, { ComponentProps, FC, ReactNode } from 'react';

import { BLACK, TEXT, WHITE } from 'config/appColors';
import { isOption, Option } from 'types';

import { TextField } from '../TextField';

const StyledAutocomplete = styled(Autocomplete)`
  background: ${WHITE};
  border-radius: 4px;

  && .MuiAutocomplete-input.MuiInputBase-input {
    padding-top: 0;
    padding-bottom: 0;
  }

  && .MuiAutocomplete-inputRoot {
    padding: 0;
  }

  .MuiButtonBase-root {
    margin-top: 5px;
    svg {
      transform: scale(1.7);
    }
  }
` as typeof Autocomplete;

const StyledOption = styled('li')`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${TEXT.PRIMARY};
`;

const StyledChipsContainer = styled('div')`
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .MuiChip-label {
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 0.016rem;
    color: ${alpha(BLACK, 0.7)};
  }
`;

interface MultiSelectProps
  extends Omit<
    ComponentProps<typeof Autocomplete>,
    'onChange' | 'options' | 'renderInput'
  > {
  options: Option[];
  value?: string[];
  onChange: (value: string[]) => void;
  onDelete: (value: string) => void;
  renderInput?: (params: AutocompleteRenderInputParams) => ReactNode;
}

export const MultiSelect: FC<MultiSelectProps> = ({
  onChange,
  onDelete,
  options,
  value: selectedValues = [],
  renderInput = (params) => <TextField {...params} />,
  ...restProps
}) => {
  // TODO: once we introduce TS 5.5.X (PL4FE-449) we can change this code to
  // const valuesToDisplay = selectedValues.map(el=>options.find(
  //   (option) => option.value === el
  // )).filter(el=>!!el);
  const valuesToDisplay = selectedValues.reduce<Option[]>(
    (acc, selectedValue) => {
      const selectedOption = options.find(
        (option) => option.value === selectedValue
      );

      if (selectedOption) {
        acc.push(selectedOption);
      }
      return acc;
    },
    []
  );

  const handleChange = (_: unknown, value: unknown) => {
    if (Array.isArray(value) && value.every(isOption)) {
      onChange(value.map(({ value: newValue }) => newValue));
    }
  };

  return (
    <>
      <StyledAutocomplete
        options={options}
        renderInput={renderInput}
        renderTags={() => null}
        renderOption={(props, option) => (
          <StyledOption {...props}>{(option as Option).label}</StyledOption>
        )}
        clearIcon={null}
        value={valuesToDisplay}
        onChange={handleChange}
        multiple
        {...restProps}
      />
      <StyledChipsContainer>
        {valuesToDisplay.map(({ label, value }) => (
          <Chip
            size="small"
            key={label}
            label={label}
            onDelete={() => onDelete(value)}
          />
        ))}
      </StyledChipsContainer>
    </>
  );
};
