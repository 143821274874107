import React from 'react';

import { yiiUrls } from '../../../config/yiiUrls';
import { AdminTableCell } from '../../../types';
import { StyledLink } from '../../atoms/StyledLink';

export const SupplierCell: AdminTableCell = ({ supplier }) => (
  <StyledLink
    target="_blank"
    rel="noopener noreferrer"
    to={{ pathname: yiiUrls.SUPPLIER_DETAILS(supplier.uuid) }}
  >
    (COID: {supplier.coid}) {supplier.name}
  </StyledLink>
);
