import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/atoms/Button';
import { PRIMARY, PRUSSIAN_BLUE } from 'config/appColors';
import { SUPPORT_URL } from 'config/constants';
import { LangHandler } from 'utils/langHandler';

const StyledContainer = styled('div')`
  font-size: 1.4rem;
  letter-spacing: 0.015rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
  }

  a {
    margin-top: 15px;
  }
`;

const StyledTitle = styled('p')`
  font-weight: 500;
  color: ${PRUSSIAN_BLUE};
`;

const StyledSubTitle = styled('p')`
  font-weight: 400;
  color: ${PRIMARY[100]};
`;

const getLinkProps = () => ({
  href: LangHandler.getTextWithLang(SUPPORT_URL),
  target: '_blank',
  rel: 'noopener noreferrer',
});

export const SupportLink: FC = () => {
  const [t] = useTranslation('components');

  return (
    <StyledContainer>
      <StyledTitle>{t('Need help')}?</StyledTitle>
      <StyledSubTitle>{t('Contact our Support')}</StyledSubTitle>
      <Button variant="contained" color="secondary" {...getLinkProps()}>
        {t('Support')}
      </Button>
    </StyledContainer>
  );
};

export default SupportLink;
