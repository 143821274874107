import DoneIcon from '@mui/icons-material/Done';
import { alpha, styled } from '@mui/material';
import MuiStep, { StepProps as MuiStepProps } from '@mui/material/Step';
import MuiStepButton from '@mui/material/StepButton';
import React, { FC } from 'react';

import { BLACK, PRIMARY, WHITE } from 'config/appColors';

interface StepState {
  $isGrayedOut: boolean;
}

const StyledMuiStepButton = styled(MuiStepButton)<StepState>`
  width: auto;
  text-align: left;

  /* LABEL STYLES */
  .MuiStepLabel-label {
    font-weight: ${({ $isGrayedOut }) => ($isGrayedOut ? 400 : 500)};
    font-size: 1.4rem;
    color: ${({ $isGrayedOut }) =>
      $isGrayedOut ? alpha(BLACK, 0.5) : alpha(BLACK, 0.7)};
    letter-spacing: 0.01rem;
  }

  /* VALUE STYLES */
  .MuiStepLabel-labelContainer {
    color: ${({ $isGrayedOut }) =>
      $isGrayedOut ? alpha(BLACK, 0.5) : alpha(BLACK, 0.7)};
    font-weight: 400;
    font-size: 1.2rem;
    letter-spacing: 0.04rem;
  }
`;

const StyledStep = styled(MuiStep)`
  height: 26px;
`;

const StyledIconContainer = styled('span')<StepState>`
  display: inline-flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${({ $isGrayedOut }) =>
    $isGrayedOut ? alpha(BLACK, 0.35) : PRIMARY[500]};
  justify-content: center;
  align-items: center;
  color: ${WHITE};
  font-size: 1.2rem;
  line-height: 2.4rem;
  font-weight: 400;
`;

const StyledDoneIcon = styled(DoneIcon)`
  fill: ${WHITE};
`;

export interface StepperStepType {
  id: string;
  label: string;
  value?: string;
}

interface StepProps extends MuiStepProps {
  step: StepperStepType;
  isActive: boolean;
  stepNumber: number;
  onStepClick?: (step: StepperStepType) => void;
}

export const Step: FC<StepProps> = (props) => {
  const { step, stepNumber, isActive, onStepClick, ...rest } = props;
  const { label, value } = step;
  const isGrayedOut = !isActive && !value;
  const icon = (
    <StyledIconContainer $isGrayedOut={isGrayedOut}>
      {value && !isActive ? <StyledDoneIcon fontSize="large" /> : stepNumber}
    </StyledIconContainer>
  );

  const handleStepClick = () => {
    // invoke the callback only if the user clicks on an inactive step
    if (!isActive) onStepClick?.(step);
  };

  return (
    <StyledStep {...rest}>
      <StyledMuiStepButton
        icon={icon}
        $isGrayedOut={isGrayedOut}
        disabled={isGrayedOut || !onStepClick}
        onClick={handleStepClick}
        optional={value}
        data-testid={label}
      >
        {label}
      </StyledMuiStepButton>
    </StyledStep>
  );
};
