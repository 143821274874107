import { css, styled } from '@mui/material';
import { Link } from 'react-router-dom';

import { METALLIC_BLUE } from 'config/appColors';

export const linkStyles = css`
  color: ${METALLIC_BLUE};
  text-decoration: underline;
  font-weight: 400;
  letter-spacing: 0.017rem;
`;

const StyledLink = styled(Link)`
  ${linkStyles}
`;

StyledLink.displayName = 'StyledLink';

export { StyledLink };
