import { alpha, styled } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { SECONDARY } from 'config/appColors';
import routes from 'config/routes';

const StyledListNav = styled(List)`
  width: 100%;

  span {
    font-weight: 500;
  }

  svg {
    font-size: 2rem;
    color: ${SECONDARY[700]};
  }
` as typeof List;

const StyledListItem = styled(ListItem)`
  box-shadow: inset 0 -1px 0 0 ${alpha(SECONDARY[50], 0.5)};
  cursor: pointer;

  span {
    color: ${SECONDARY[700]};
  }

  &,
  &:hover {
    background: transparent;
  }
`;

export const SidebarMenu: FC = () => {
  const [t] = useTranslation('components');
  const { push } = useHistory();

  return (
    <StyledListNav component="nav">
      <StyledListItem onClick={() => push(routes.ADMIN_REPORTS_ALL_AUDITS)}>
        <ListItemText>{t('All Audits')}</ListItemText>
      </StyledListItem>
    </StyledListNav>
  );
};

export default SidebarMenu;
